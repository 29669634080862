<template>
  <div class="admin">
    <div class="inline-menu">
      <span @click="switchTab('news')" class="tab-title">NEWS</span>
      <span @click="switchTab('event')" class="tab-title">EVENTS</span>
      <span @click="switchTab('products')" class="tab-title">PRODUCTS</span>
    <div v-if="showTab == 'news'">
      <NewsRegist/>
    </div>
    <div v-else-if="showTab == 'event'">
      <EventRegist/>
    </div>
    <div v-else-if="showTab == 'products'">
      <ProductRegist/>
    </div>
    </div>
  </div>
</template>

<script>
import NewsRegist from './NewsRegist.vue';
import EventRegist from './EventRegist.vue';
import ProductRegist from './ProductRegist.vue';

export default {
  name: 'Admin',
  components: {
    NewsRegist,
    EventRegist,
    ProductRegist
  },
  props: {
  },
  data() {
    return {
      showTab: 'news'
    }
  },
  mounted() {
  },
  methods: {
    switchTab(target) {
      this.showTab = target
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tab-title {
  padding: 1vw 2vw;
  background-color: #c59556;
  margin: 0 2vw 2vw;
  color: white;
  font-weight: bold;
  font-size: 20px;
}

</style>
