import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import router from './route/router.js'
import 'tw-elements'
import firebase from './config/firebase.js'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions'

const func = getFunctions();
func.region = "asia-northeast1";

const db = getFirestore(firebase);

const storage = getStorage(firebase);

export default { func, db, storage };

createApp(App).use(firebase).use(router).mount('#app')