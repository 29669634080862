<template>
  <div class="event">
    <div class="event_content">
      <div v-if="item.finFlag" class="fin_event_div">
        <span class="fin_event">イベントは終了しました</span>
      </div>
      <div v-else-if="item.fullHouseFlag" class="fin_event_div">
        <span class="fin_event">満員御礼！</span>
      </div>
      <img v-bind:src="item.downloadUrl" alt="">
      <table>
        <tr>
          <td rowspan="2">日程</td>
          <td>{{ item.startDateFmt }}</td>
        </tr>
        <tr>
          <td>{{ item.endDateFmt }}</td>
        </tr>
      </table>
      <div class="title">
        <span>{{ item.title }}</span>
      </div>
      <div class="btn-div">
        <router-link class="detail-btn" :to="{name: 'EventDetail', query: {id: item.id}}">詳細</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export default {
  data() {
    return {
      item: {},
      showContent: false
    }
  },
  props: {
    value: String
  },
  mounted() {
    this.item = JSON.parse(this.value);
    this.downloadUrl();
    let fmtDatetime = this.toDateTime(this.item.startDatetime);
    this.item.startDateFmt = fmtDatetime.dateFmt;
    this.item.startTimeFmt = fmtDatetime.timeFmt;
    this.item.startDate = fmtDatetime.date;
    fmtDatetime = this.toDateTime(this.item.endDatetime);
    this.item.endDateFmt = fmtDatetime.dateFmt;
    this.item.endTimeFmt = fmtDatetime.timeFmt;
    this.item.endDate = fmtDatetime.date;

    if (this.item.startDate.getTime() < new Date().getTime()) {
      this.item.finFlag = true;
    } else {
      this.item.finFlag = false;
    }

  },
  methods: {
    toDateTime(datetime) {
      var date = new Date(1970, 0, 1);
      date.setSeconds(datetime.seconds);
      date.setHours(date.getHours() + 9);
      return {
        dateFmt: date.getFullYear() + "/" + ("00" + (date.getMonth()+1)).slice(-2) + "/" + ("00" + date.getDate()).slice(-2),
        timeFmt: ("00" + date.getHours()).slice(-2) + ":" + ("00" + date.getMinutes()).slice(-2) + ":" + ("00" + date.getSeconds()).slice(-2),
        date: date
      };
    },
    async downloadUrl() {
      if (this.item.imageurl) {
        const storage = getStorage();
        const imgFullPath = this.item.imageurl;
        let targetItem = this;
        await getDownloadURL(ref(storage, "events-contents-image/" + imgFullPath))
        .then(function(url) {
          targetItem.item.downloadUrl = url;
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
    openModal() {
      this.showContent = true;
    },
    closeModal() {
      this.showContent = false;
    }
  }
}
</script>
<style scoped>
.event {
  display: inline-flex;
  width: 17vw;
  background-color: white;
  border-radius: 5px;
}
img {
  width: 16vw;
  height: 9vw;
  object-fit: cover;
  margin: 0.5vw 0.5vw 0;
  object-position: 0 0;
}
table {
  position: relative;
}
table td {
  text-align: left;
  padding-left: 10px;
  vertical-align: top;
  font-size: 1.3vw;
}
.btn-div {
  margin-bottom: 1vw;
}
.detail-btn {
  background-color: #af9453;
  color: white;
  padding: 0.5vw 2vw;
}
.title span {
  height: 5vw;
  max-height: 5vw;
  color: #af9453;
  font-size: 1.2vw;
  position: relative;
  line-height: 1.6vw;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-wrap: anywhere;
  overflow: hidden;
  text-align: left;
  padding-left: 6px;
}
.fin_event_div {
  position: relative;
}
.fin_event {
  position: absolute;
  color: white;
  font-weight: bold;
  font-size: 1.1vw;
  width: 13vw;
  right: 2vw;
  top: 3.5vw;
}
@media screen and (max-width:640px) {
  .event_content {
    width: 100%;
  }
  img {
    position: relative;
    width: 95%;
    height: 50vw;
    object-fit: cover;
    margin: 0 auto;
  }
  .event {
    display: inline-flex;
    width: 90%;
    border: 1px solid black;
  }
  .fin_event {
    position: relative;
    color: white;
    font-weight: bold;
    font-size: 4vw;
    top: 20vw;
    background-color: rgba(0,0,0,0.6);
    z-index: 1;
  }
  .title span {
    color: #af9453;
    font-size: 4vw;
    position: relative;
    line-height: 4.2vw;
    text-align: center;
    padding-left: 0;
    height: 12vw;
    max-height: 12vw;
    margin-top: 2vw;
  }
  table {
    position: relative;
    margin: 0 auto;
  }
  table td {
    text-align: left;
    padding-left: 10px;
    vertical-align: top;
    font-size: 4vw;
  }
  .detail-btn {
    background-color: #af9453;
    color: white;
    padding: 1.0vw 4vw;
  }
}
</style>