<template>
  <div class="privacy">
    <div class="kojin">
      <div>個人情報の取扱いについて</div>
      <div>
        個人情報とは、個人に関する情報であって、その情報を構成する氏名、住所、電話番号、メールアドレス、勤務先、生年月日その他の記述等により個人を識別できるものをいいます。また、その情報のみでは識別できない場合でも、他の情報と容易に照合することができ、結果的に個人を識別できるものも個人情報に含まれます。
      </div>
    </div>
    <div>
      ■個人情報の利用
      <hr>
ご登録いただいた個人情報は、以下の利用目的の範囲内で利用します。以下の利用目的の範囲を超えて利用する場合、本人による事前の同意の下にこれを利用します。

＜利用目的＞
(1)サービスなどお問い合わせ、メールマガジンへの登録で取得した情報
・当会から本人へのご連絡、当会サービス、セミナーなどのご案内に利用します
(2)セミナーにお申し込み頂いた際に取得した情報
・当会から本人への連絡、当会サービス、セミナーなどのご案内およびセミナーの運営に利用します。
    </div>
    <div>
      ■個人情報の管理について
      <hr>
個人情報への不当なアクセスまたは漏えい、滅失又はき損の危険に対して、必要かつ適切な安全対策を継続的に講じるよう努めます。
    </div>
    <div>
      ■個人情報の開示請求等について
      <hr>
ご登録いただいた個人情報は、原則として本人に限り、利用目的の通知、開示、および内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止(以下、“開示等”といいます)を求めることができます。個人情報の開示等のご依頼(以下、“開示請求等”といいます)があった場合は、本人であることが確認できた場合のみ、合理的な範囲(弊社事業・サービスの遂行に支障を及ぼさぬ範囲)において、速やかに対応します。
開示請求等の方法については、以下の連絡先にお問い合わせ下さい。その際に必要な手続き、手順等について説明させていただきます。
    </div>
    <div>
      ■個人情報の第三者への提供について
      <hr>
ご登録いただいた個人情報については、本人の同意を得ずに第三者に提供することはいたしません。ただし、関係法令に反しない範囲で同意を得ずに提供することがあります。
以下の場合には、個人情報の提供を受ける者は第三者に該当しないものとします。
(1)当会が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合(なお、委託先における個人情報の取扱いについては当会が責任を負います。)
(2)当会の合併その他の事由による事業の承継に伴って個人情報が提供される場合
    </div>
    <div>
      ■個人情報を提供しなかった場合に生じる結果について
      <hr>
登録フォームに記入するにあたり、必ずしもすべての項目を弊社に提供いただく必要はありませんが、各サービス等の実施においてそれぞれ必要となる項目を記入いただかない場合には、各々のサービスの全部又は一部を受けられないことがあります。
    </div>
    <div>
      ■個人情報管理責任者について
      <hr>
ご登録いただいた個人情報は、以下の者が責任をもって管理するものとします。
Unity合同会社 情報管理責任者
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
    }
  },
  props: {
  },
  mounted() {
  },
  unmounted() {
  },
  methods: {
  }
}
</script>
<style scoped>
.privacy {
  width: 80%;
  margin: 0 auto;
}
.kojin {
  width: 60%;
}
</style>