<template>
  <div class="about">
    <div class="relative w-full">
      <img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Ftop-slide-4.png?alt=media&token=71c68bb1-679e-401f-9c7e-2f837f4b3949" class="block w-full" alt="yasai" />
    </div>
    <div class="service-separete">CONCEPT</div>
    <div class="mt-6">
      <span class="text-xs">
        信州を舞台に<br/>
        生命を感じる農作物をつくる。<br/>
        <br/>
        人と人が田んぼや畑を通して<br/>
        体験や交流を通して<br/>
        つながることができる。<br/>
        <br/>
        いつしか観客は主人公になり、<br/>
        新しい舞台がはじまる。<br/>
        <br/>
        またすぐ帰ってきたくなる。<br/>
        <br/>
        分かち合う<br/>
        新しいふるさとを持とう。<br/>
      </span>
    </div>
    <div class="grid place-content-center">
      <img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fhome-farmy-1.png?alt=media&token=a233c3f4-4d58-4f5a-b48a-236b1ca0634a" alt="hatake illust">
    </div>
    <div class="service-separete">ABOUT</div>
    <div class="mt-6">
      <span class="text-xs">
        信州の雄大な山々の麓で、<br/>
        土自体の力を最大限発揮した無農薬・無化学肥料栽培の<br/>
        田畑のオーナーズコミュニティ。<br/>
        安心・安全な野菜や米を求めている人たちと信州の農家さんが<br/>
        支えあいながら田畑を育て収穫を分け合う。<br/>
        好きな時に農体験ができ、イベントや交流を通して地域と<br/>
        つながることができる場所です。
      </span>
    </div>
    <div class="yurai inline-grid grid-cols-2 mt-20 relative">
      <img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fhome-farmy-logo.png?alt=media&token=f3e0e5cd-c5d2-4e6c-b373-050739f511f8" alt="farmy logo">
      <span class="absolute inset-x-0 bottom-2 left-12">の由来</span>
    </div>
    <div class="yurai-text mt-6">
      <span class="text-xs">
        FARMYとは、Farm＋Familyの造語です。<br/>
        ここで言うFarmとは、単に作物を育てる畑としての意味だけではありません。<br/>
        生きていくために食べ物と同じくらい無くてはならない大切なもの。<br/>
        たとえば「感性」「美意識」「気づき」「感動」「絆」「喜び」「生きがい」など。<br/>
        目には見えないけれど人生に必要な大切な価値を、<br/>
        家族で体験して一緒に育てていける畑でもあってほしい。<br/>
        <br/>
        そんな思いをこめてFARMYというコトバが生まれました。<br/>
      </span>
    </div>
    <div class="grid place-content-center mt-10">
      <iframe width="548" height="308" src="https://www.youtube.com/embed/_6QA-74D3wI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  props: {
  },
  mounted() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
@media screen and (max-width:640px) {
  .yurai {
    margin-left: 6rem;
  }
  .yurai-text {
    width: 80%;
    margin: 0 auto;
  }
}
</style>
