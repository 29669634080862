<template>
  <div class="specifiedCommercial">
    <span class="title">特定商取引に基づく表記</span>
    <table>
      <tr>
        <td>事業者名</td>
        <td>Unity合同会社（Unity LLC）</td>
      </tr>
      <tr>
        <td>販売者・運営責任者名</td>
        <td>代表社員   日吉  有為</td>
      </tr>
      <tr>
        <td>所在地</td>
        <td>〒399-0737<br>長野県塩尻市大門八番町１−２８</td>
      </tr>
      <tr>
        <td>電話番号</td>
        <td>050-6879-4187</td>
      </tr>
      <tr>
        <td>メールアドレス</td>
        <td>contact@donaru.jp</td>
      </tr>
      <tr>
        <td>お支払い方法</td>
        <td>ご注文から7日以内に発送いたします</td>
      </tr>
      <tr>
        <td>お支払い方法</td>
        <td>クレジットカード 銀行振込</td>
      </tr>
      <tr>
        <td>商品代金以外の必要金額</td>
        <td>銀行振込の場合、振込手数料</td>
      </tr>
      <tr>
        <td>お申込み有効期限</td>
        <td>銀行振込の場合、7日以内にお願いいたします。</td>
      </tr>
      <tr>
        <td>返品期限</td>
        <td>返品期限：商品到着より7日以内</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  props: {
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style scoped>
  .specifiedCommercial {
    margin-top: 4vw;
  }
  .title {
    position: relative;
    width: 100%;
    color: #af9453;
    font-size: 2vw;
  }
  table {
    text-align: left;
    margin: 4vw auto;
    font-size: 1vw;
  }
  td {
    padding: 1vw 0 3vw 1vw;
  }
  td:nth-child(1) {
    width: 15vw;
    font-weight: bold;
    color: #af9453;
    border-top: 2px solid black;
  }
  td:nth-child(2) {
    border-top: 1px solid black;
  }
  tr:last-child td:nth-child(1) {
    border-bottom: 2px solid black;
  }
  tr:last-child td:nth-child(2) {
    border-bottom: 1px solid black;
  }

  @media screen and (max-width:640px) {
    .title {
      font-size: 5vw;
    }
    table {
      font-size: 3vw;
      width: 90%;
    }
    td:nth-child(1) {
      width: 25%;
    }

  }
</style>