<template>
  <div class="product-detail">
    <div class="title">{{ item.title }}</div>
    <div v-if="item.imageurl">
      <img class="top-img" :src="item.imageurl" alt="">
    </div>
    <div class="content">
      <span v-html="autoLink(item.content)"></span>
    </div>
    <div v-if="item.imageurl2">
      <img class="top-img" :src="item.imageurl2" alt="">
    </div>
    <div class="content">
      <span v-html="autoLink(item.content2)"></span>
    </div>
    <div class="urls">
      <div class="url-content">
        <div>{{ item.title1 }}</div>
        <a class="card" :href="item.url1" target="_blank">ご購入はこちら</a>
      </div>
      <div v-if="item.title2" class="url-content">
        <div>{{ item.title2 }}</div>
        <a class="card" :href="item.url2" target="_blank">ご購入はこちら</a>
      </div>
      <div v-if="item.title3" class="url-content">
        <div>{{ item.title3 }}</div>
        <a class="card" :href="item.url3" target="_blank">ご購入はこちら</a>
      </div>
      <div v-if="item.title4" class="url-content">
        <div>{{ item.title4 }}</div>
        <a class="card" :href="item.url4" target="_blank">ご購入はこちら</a>
      </div>
      <div v-if="item.title5" class="url-content">
        <div>{{ item.title5 }}</div>
        <a class="card" :href="item.url5" target="_blank">ご購入はこちら</a>
      </div>
      <div v-if="item.title6" class="url-content">
        <div>{{ item.title6 }}</div>
        <a class="card" :href="item.url6" target="_blank">ご購入はこちら</a>
      </div>
      <div v-if="item.title7" class="url-content">
        <div>{{ item.title7 }}</div>
        <a class="card" :href="item.url7" target="_blank">ご購入はこちら</a>
      </div>
      <div v-if="item.title8" class="url-content">
        <div>{{ item.title8 }}</div>
        <a class="card" :href="item.url8" target="_blank">ご購入はこちら</a>
      </div>
      <div v-if="item.title9" class="url-content">
        <div>{{ item.title9 }}</div>
        <a class="card" :href="item.url9" target="_blank">ご購入はこちら</a>
      </div>
      <div v-if="item.title10" class="url-content">
        <div>{{ item.title10 }}</div>
        <a class="card" :href="item.url10" target="_blank">ご購入はこちら</a>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../main.js"
import { doc, getDoc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
export default {
  name: 'ProductDetail',
  data() {
    return {
      id: null,
      item : {},
    }
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.awaitFunc();
  },
  methods: {
    autoLink(text) {
      return text ? text.replace(/(https?:\/\/[^\s]*)/g, "<a href='$1' style='color:blue;' target='_blank'>$1</a>") : '';
    },
    async awaitFunc() {
      const docRef = doc(firebase.db, "Products", this.id);
      const querySnapshot = await getDoc(docRef);
      this.item = querySnapshot.data();
      const storage = getStorage();
      if (this.item.imageurl) {
        let targetItem = this;
        await getDownloadURL(ref(storage, "products-contents-image/" + this.item.imageurl))
        .then(function(url) {
          targetItem.item.imageurl = url;
        })
        .catch((error) => {
          console.log(error);
        });
      }
      if (this.item.imageurl2) {
        let targetItem = this;
        await getDownloadURL(ref(storage, "products-contents-image/" + this.item.imageurl2))
        .then(function(url) {
          targetItem.item.imageurl2 = url;
        })
        .catch((error) => {
          console.log(error);
        });
      }
      if (this.item.content.indexOf("gagagagazou") >= 0) {
        let contents = this.item.content.split("gagagagazou");
        this.item.content = contents[0];
        this.item.content2 = contents[1];
      }
    },
  }
}
</script>
<style scoped>
.title {
  font-size: 40px;
  color: #AF9453;
}
.sub-title {
  width: 670px;
  margin: 0 auto;
  font-size: 30px;
  color: #AF9453;
  text-align: left;
}
.product-detail {
  width: 100%;
  display: inline-grid;
}
.top-img {
  margin: 1vw auto 1vw;
  width: 670px;
  height: auto;
}
.content {
  margin: 1vw auto 1vw;
  width: 650px;
  text-align: left;
  white-space: pre-line;
}
.urls {
  position: fixed;
  top: 7vw;
  right: 2vw;
  font-size: 1vw;
  line-height: 1.6vw;
  text-align: left;
}
.url-content {
  margin-bottom: 10px;
  background: antiquewhite;
  width: 16vw;
  height: 3vw;
  border-radius: 15px;
  border: 2px solid #AF9453;
  font-size: 1vw;
  text-align: center;
  line-height: 1.2vw;
}
@media screen and (max-width:640px) {
  .title {
    font-size: 6vw;
  }
  .content {
    width: 80%;
  }
  .urls {
    position: relative;
    text-align: left;
    width: 100%;
  }
  .url-content {
    margin: 0 auto 4vw;
    background: antiquewhite;
    width: 60%;
    height: 15vw;
    border-radius: 15px;
    border: 2px solid #AF9453;
    text-align: center;
    font-size: 6vw;
    line-height: 6.5vw;
  }
}
</style>
