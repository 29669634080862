import { createRouter, createWebHistory } from 'vue-router'
import Admin from '../views/Admin.vue'
import SigninSignup from '../components/SigninSignup.vue'

import Home from '../views/Home.vue'
import NewsDetail from '../views/NewsDetail.vue'
import NewsList from '../views/NewsList.vue'
import About from '../views/About.vue'
import ShareFarm from '../views/ShareFarm.vue'
import Events from '../views/Events.vue'
import EventDetail from '../views/EventDetail.vue'
import OnlineSeminar from '../views/OnlineSeminar.vue'
import Contact from '../views/Contact.vue'
import Company from '../views/Company.vue'
import SpecifiedCommercial from '../views/SpecifiedCommercial.vue'
import Products from '../views/Products.vue'
import ProductDetail from '../views/ProductDetail.vue'

import User from '../views/User.vue'

import Yakkan from  '../views/Yakkan.vue'
import Joken from  '../views/Joken.vue'

import Ryoko from  '../views/Ryoko.vue'

import Privacy from  '../views/Privacy.vue'

const routes = [
  { path: '/admin', name: 'Admin', component: Admin },
  { path: '/signin-signup', name: 'SigninSignup', component: SigninSignup },

  { path: '/', name: 'Home', component: Home },
  { path: '/news-detail', name: 'NewsDetail', component: NewsDetail },
  { path: '/news-list', name: 'NewsList', component: NewsList },
  { path: '/about', name: 'About', component: About },
  { path: '/share-farm', name: 'ShareFarm', component: ShareFarm },
  { path: '/events', name: 'Events', component: Events },
  { path: '/event-detail', name: 'EventDetail', component: EventDetail },
  { path: '/online-seminar', name: 'OnlineSeminar', component: OnlineSeminar },
  { path: '/contact', name: 'Contact', component: Contact },
  { path: '/company', name: 'Company', component: Company },
  { path: '/specified-commercial', name: 'SpecifiedCommercial', component: SpecifiedCommercial },
  { path: '/products', name: 'Products', component: Products },
  { path: '/product-detail', name: 'ProductDetail', component: ProductDetail },

	{ path: '/user', name: 'User', component: User },

	{ path: '/yakkan', name: 'Yakkan', component: Yakkan },
	{ path: '/joken', name: 'Joken', component: Joken },

  { path: '/ryoko', name: 'Ryoko', component: Ryoko },

  { path: '/privacy', name: 'Privacy', component: Privacy },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0
      }
    }
  }
})

export default router
