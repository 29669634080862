<template>
  <div class="news">
    <table>
      <tr>
        <td>{{ item.dateFmt }}</td>
        <td rowspan="2">
          <router-link class="new-read-more" :to="{name: 'NewsDetail', query: {id: item.id}}">
            {{ item.title }}
          </router-link>
        </td>
      </tr>
      <tr>
        <td>
          {{ item.timeFmt }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: {},
      // downloadUrlList: []
    }
  },
  props: {
    value: String
  },
  mounted() {
    this.item = JSON.parse(this.value);
    let fmtDatetime = this.toDateTime(this.item.datetime);
    this.item.dateFmt = fmtDatetime.dateFmt;
    this.item.timeFmt = fmtDatetime.timeFmt;
  },
  methods: {
    toDateTime(datetime) {
      var date = new Date(1970, 0, 1);
      date.setSeconds(datetime.seconds);
      date.setHours(date.getHours() + 9);
      return {
        dateFmt: date.getFullYear() + "/" + ("00" + (date.getMonth()+1)).slice(-2) + "/" + ("00" + date.getDate()).slice(-2),
        timeFmt: ("00" + date.getHours()).slice(-2) + ":" + ("00" + date.getMinutes()).slice(-2) + ":" + ("00" + date.getSeconds()).slice(-2)
      };
    },
  }
}
</script>
<style scoped>
.news {
  display: inline-flex;
  width: 100%;
}
.title {
  font-size: 1.6vw;
  margin-bottom: 1vw;
}
table {
  position: relative;
  width: 100%;
}
table td {
  text-align: left;
  padding-left: 10px;
}
table td:first-child {
  width: 25%;
}
button {
  background-color: #af9453;
  color: white;
  width: 14vw;
  height: 3vw;
  border-radius: 20px;
}
#overlay{
  /* 要素を重ねた時の順番 */
  z-index:1;
  /* 画面全体を覆う設定 */
  position:fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background-color:rgba(0,0,0,0.5);
  /* 画面の中央に要素を表示させる設定 */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}
#content{
  z-index:2;
  width:50%;
  height: 80%;
  padding: 1em;
  background:#fff;
  overflow-y: auto;
}
.content-close {
  margin-top: 2vw;
  background-color: #AF9453;
  color: white;
  text-align: center;
  text-indent: 0vw;
  width: 30%;
}
.new-read-more {
  color: #AF9453;
  position: relative;
  cursor: pointer;
}
@media screen and (max-width:640px) {
  #content{
	width: 90% !important;
	height: 98% !important;
  }
  button {
    background-color: transparent;
    color: #AF9453;
    width: 100%;
    height: 7vw;
    text-align: left;
    text-indent: 6vw;
  }
  .content-close {
    margin-top: 5vw;
    background-color: #AF9453;
    color: white;
    text-align: center;
    text-indent: 0vw;
    width: 30%;
  }
  .new-read-more {
    margin-top: 2vw;
    background-color: #AF9453;
    color: white;
    text-align: center;
    text-indent: 0vw;
    width: 30%;
  }
}
</style>