<template>
  <div class="joken">
    <div class="pdf-div" v-for="num of pageNum" :key="num">
      <VuePDF :pdf="pdf" :page="num" fit-parent />
    </div>
  </div>
</template>

<script>
import { VuePDF, usePDF } from '@tato30/vue-pdf'

export default {
  components: {
    VuePDF
  },
  data() {
    return {
      pageNum: 2
    }
  },
  props: {
  },
  setup() {
    const { pdf, pages, info } = usePDF("joken.pdf")
    console.log(`Document has ${pages} pages`)
    console.log(`Document info: ${info}`)
    return {
      pdf
    }
  },
  mounted() {
    document.getElementsByClassName("header")[0].style.display = "none";
    document.getElementsByClassName("footer")[0].style.display = "none";
  },
  unmounted() {
    document.getElementsByClassName("header")[0].style.display = "block";
    document.getElementsByClassName("footer")[0].style.display = "block";
  },
  methods: {
  }
}
</script>
<style scoped>
.joken {
  background-color: #252525;
  width: 100%;
}
.pdf-div {
  width: 90%;
  margin: 0 auto;
  padding: 1vw 0;
}
</style>