<template>
  <div class="products-regist">
    <div class="product-title">商品の登録</div>
    <div id="input-product">
      <div class="inline-flex w-full">
        <div class="history-block block">
          <div class="history">
            <div class="product-dl" v-for="(item, key) in products" :key="key">
              <table>
                <tr>
                  <td>{{ item.title }}</td>
                  <td><ion-icon class="ud-icon" @click="deploymentProduct(item)" name="chevron-forward-outline"></ion-icon></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="input-block">
          <table class="input-table">
            <tr>
              <td>タイトル</td>
              <td><input type="text" name="product_title"></td>
            </tr>
            <tr>
              <td>文章</td>
              <td><textarea name="product_content" id="" cols="30" rows="10"></textarea></td>
            </tr>
            <tr>
              <td>商品決済1 タイトル</td>
              <td><input type="text" name="product1_title"></td>
            </tr>
            <tr>
              <td>商品決済1 URL</td>
              <td><input type="text" name="product1_url"></td>
            </tr>
            <tr>
              <td>商品決済2 タイトル</td>
              <td><input type="text" name="product2_title"></td>
            </tr>
            <tr>
              <td>商品決済2 URL</td>
              <td><input type="text" name="product2_url"></td>
            </tr>
            <tr>
              <td>商品決済3 タイトル</td>
              <td><input type="text" name="product3_title"></td>
            </tr>
            <tr>
              <td>商品決済3 URL</td>
              <td><input type="text" name="product3_url"></td>
            </tr>
            <tr>
              <td>商品決済4 タイトル</td>
              <td><input type="text" name="product4_title"></td>
            </tr>
            <tr>
              <td>商品決済4 URL</td>
              <td><input type="text" name="product4_url"></td>
            </tr>
            <tr>
              <td>商品決済5 タイトル</td>
              <td><input type="text" name="product5_title"></td>
            </tr>
            <tr>
              <td>商品決済5 URL</td>
              <td><input type="text" name="product5_url"></td>
            </tr>
            <tr>
              <td>商品決済6 タイトル</td>
              <td><input type="text" name="product6_title"></td>
            </tr>
            <tr>
              <td>商品決済6 URL</td>
              <td><input type="text" name="product6_url"></td>
            </tr>
            <tr>
              <td>商品決済7 タイトル</td>
              <td><input type="text" name="product7_title"></td>
            </tr>
            <tr>
              <td>商品決済7 URL</td>
              <td><input type="text" name="product7_url"></td>
            </tr>
            <tr>
              <td>商品決済8 タイトル</td>
              <td><input type="text" name="product8_title"></td>
            </tr>
            <tr>
              <td>商品決済8 URL</td>
              <td><input type="text" name="product8_url"></td>
            </tr>
            <tr>
              <td>商品決済9 タイトル</td>
              <td><input type="text" name="product9_title"></td>
            </tr>
            <tr>
              <td>商品決済9 URL</td>
              <td><input type="text" name="product9_url"></td>
            </tr>
            <tr>
              <td>商品決済10 タイトル</td>
              <td><input type="text" name="product10_title"></td>
            </tr>
            <tr>
              <td>商品決済10 URL</td>
              <td><input type="text" name="product10_url"></td>
            </tr>
            <tr>
              <td>画像</td>
              <td>
                <input type="file" @change="productFileUpload">
              </td>
            </tr>
            <tr>
              <td></td>
              <td class="imgs">
                <div v-if="product_img_download_url">
                  <img v-bind:src="product_img_download_url" alt="">
                </div>
              </td>
            </tr>
            <tr>
              <td>途中に差し込む画像<br>本文中に「gagagagazou」<br>と打ち込んでください
              </td>
              <td>
                <input type="file" @change="productFileUpload2">
              </td>
            </tr>
            <tr>
              <td></td>
              <td class="imgs">
                <div v-if="product_img_download_url2">
                  <img v-bind:src="product_img_download_url2" alt="">
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <div class="input-ud-div">
                  <ion-icon class="input-ud-icon" @click="deleteData" name="trash-outline"></ion-icon>
                  <ion-icon class="input-ud-icon" @click="productPostData" name="save-outline"></ion-icon>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../main.js";
import { collection, doc, addDoc, setDoc, getDocs, deleteDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default {
  name: 'ProductsRegist',
  components: {
  },
  props: {
  },
  data() {
    return {
      // News
      products: [],
      product_data_id: String,
      product_img_full_path: null,
      product_img_download_url: null,
      product_img_full_path2: null,
      product_img_download_url2: null,
    }
  },
  mounted() {
    this.awaitFunc();
  },
  methods: {
    async awaitFunc() {
      this.clearProduct();
      this.products.length = 0;
      this.product_data_id = null;
      const productsQuerySnapshot = await getDocs(collection(firebase.db, "Products"));
      productsQuerySnapshot.forEach((doc) => {
        var data = doc.data();
        data.id = doc.id;
        this.products.push(data);
      });

    },
    deploymentProduct(item) {
      document.getElementsByName("product_title")[0].value = item.title;
      document.getElementsByName("product_content")[0].value = item.content;
      document.getElementsByName("product1_title")[0].value = item.title1;
      document.getElementsByName("product1_url")[0].value = item.url1;
      document.getElementsByName("product2_title")[0].value = item.title2;
      document.getElementsByName("product2_url")[0].value = item.url2;
      document.getElementsByName("product3_title")[0].value = item.title3;
      document.getElementsByName("product3_url")[0].value = item.url3;
      document.getElementsByName("product4_title")[0].value = item.title4;
      document.getElementsByName("product4_url")[0].value = item.url4;
      document.getElementsByName("product5_title")[0].value = item.title5;
      document.getElementsByName("product5_url")[0].value = item.url5;
      document.getElementsByName("product6_title")[0].value = item.title6;
      document.getElementsByName("product6_url")[0].value = item.url6;
      document.getElementsByName("product7_title")[0].value = item.title7;
      document.getElementsByName("product7_url")[0].value = item.url7;
      document.getElementsByName("product8_title")[0].value = item.title8;
      document.getElementsByName("product8_url")[0].value = item.url8;
      document.getElementsByName("product9_title")[0].value = item.title9;
      document.getElementsByName("product9_url")[0].value = item.url9;
      document.getElementsByName("product10_title")[0].value = item.title10;
      document.getElementsByName("product10_url")[0].value = item.url10;
      this.product_data_id = item.id;

      var admin = this;
      this.product_img_download_url = null;
      this.product_img_full_path = item.imageurl ? item.imageurl : null;
      if (this.product_img_full_path.length > 0) {
        const storage = getStorage();
        if (this.product_img_full_path) {
          const imgFullPath = this.product_img_full_path;
          getDownloadURL(ref(storage, "products-contents-image/" + imgFullPath))
          .then(function(url) {
            admin.product_img_download_url = url;
          })
          .catch((error) => {
            console.log(error);
          });
        }
      }
      this.product_img_download_url2 = null;
      this.product_img_full_path2 = item.imageurl2 ? item.imageurl2 : null;
      if (this.product_img_full_path2.length > 0) {
        const storage = getStorage();
        if (this.product_img_full_path2) {
          const imgFullPath2 = this.product_img_full_path2;
          getDownloadURL(ref(storage, "products-contents-image/" + imgFullPath2))
          .then(function(url) {
            admin.product_img_download_url2 = url;
          })
          .catch((error) => {
            console.log(error);
          });
        }
      }
    },
    clearProduct() {
      document.getElementsByName("product_title")[0].value = "";
      document.getElementsByName("product_content")[0].value = "";
      document.getElementsByName("product1_title")[0].value = "";
      document.getElementsByName("product1_url")[0].value = "";
      document.getElementsByName("product2_title")[0].value = "";
      document.getElementsByName("product2_url")[0].value = "";
      document.getElementsByName("product3_title")[0].value = "";
      document.getElementsByName("product3_url")[0].value = "";
      document.getElementsByName("product4_title")[0].value = "";
      document.getElementsByName("product4_url")[0].value = "";
      document.getElementsByName("product5_title")[0].value = "";
      document.getElementsByName("product5_url")[0].value = "";
      document.getElementsByName("product6_title")[0].value = "";
      document.getElementsByName("product6_url")[0].value = "";
      document.getElementsByName("product7_title")[0].value = "";
      document.getElementsByName("product7_url")[0].value = "";
      document.getElementsByName("product8_title")[0].value = "";
      document.getElementsByName("product8_url")[0].value = "";
      document.getElementsByName("product9_title")[0].value = "";
      document.getElementsByName("product9_url")[0].value = "";
      document.getElementsByName("product10_title")[0].value = "";
      document.getElementsByName("product10_url")[0].value = "";
    },
    async productPostData() {
      const data = {
        title: document.getElementsByName("product_title")[0].value,
        content: document.getElementsByName("product_content")[0].value,
        title1: document.getElementsByName("product1_title")[0].value,
        url1: document.getElementsByName("product1_url")[0].value,
        title2: document.getElementsByName("product2_title")[0].value,
        url2: document.getElementsByName("product2_url")[0].value,
        title3: document.getElementsByName("product3_title")[0].value,
        url3: document.getElementsByName("product3_url")[0].value,
        title4: document.getElementsByName("product4_title")[0].value,
        url4: document.getElementsByName("product4_url")[0].value,
        title5: document.getElementsByName("product5_title")[0].value,
        url5: document.getElementsByName("product5_url")[0].value,
        title6: document.getElementsByName("product6_title")[0].value,
        url6: document.getElementsByName("product6_url")[0].value,
        title7: document.getElementsByName("product7_title")[0].value,
        url7: document.getElementsByName("product7_url")[0].value,
        title8: document.getElementsByName("product8_title")[0].value,
        url8: document.getElementsByName("product8_url")[0].value,
        title9: document.getElementsByName("product9_title")[0].value,
        url9: document.getElementsByName("product9_url")[0].value,
        title10: document.getElementsByName("product10_title")[0].value,
        url10: document.getElementsByName("product10_url")[0].value,
        updateDate: new Date(),
        imageurl: this.product_img_full_path,
        imageurl2: this.product_img_full_path2,
      };
      if (this.product_data_id) {
        await setDoc(doc(firebase.db, "Products", this.product_data_id), data);
        alert("商品データが更新されました");

      } else {
        data.createDate = new Date();
        const db = collection(firebase.db, "Products");
        await addDoc(db, data);
        alert("商品データが登録されました");
      }
      this.awaitFunc();

    },
    async deleteData() {
      if (this.product_data_id) {
        if (confirm(document.getElementsByName("product_title")[0].value + "を削除してもいいですか？")) {
          await deleteDoc(doc(firebase.db, "Products", this.product_data_id));
          this.product_data_id = null;
          document.getElementsByName("product_title")[0].value = "";
          document.getElementsByName("product_content")[0].value = "";
          document.getElementsByName("product1_title")[0].value = "";
          document.getElementsByName("product1_url")[0].value = "";
          document.getElementsByName("product2_title")[0].value = "";
          document.getElementsByName("product2_url")[0].value = "";
          document.getElementsByName("product3_title")[0].value = "";
          document.getElementsByName("product3_url")[0].value = "";
          document.getElementsByName("product4_title")[0].value = "";
          document.getElementsByName("product4_url")[0].value = "";
          document.getElementsByName("product5_title")[0].value = "";
          document.getElementsByName("product5_url")[0].value = "";
          document.getElementsByName("product6_title")[0].value = "";
          document.getElementsByName("product6_url")[0].value = "";
          document.getElementsByName("product7_title")[0].value = "";
          document.getElementsByName("product7_url")[0].value = "";
          document.getElementsByName("product8_title")[0].value = "";
          document.getElementsByName("product8_url")[0].value = "";
          document.getElementsByName("product9_title")[0].value = "";
          document.getElementsByName("product9_url")[0].value = "";
          document.getElementsByName("product10_title")[0].value = "";
          document.getElementsByName("product10_url")[0].value = "";
        }
        this.awaitFunc();

      } else {
        alert("対象データがセットされていません");
      }
    },
    productFileUpload(event) {
      let file = event.target.files[0];

      const storage = getStorage();
      const storageRef = ref(storage, "products-contents-image/" + file.name);
      uploadBytes(storageRef, file)
      .then((snapshot) => {
        this.product_img_full_path = snapshot.metadata.name;
      });
    },
    productFileUpload2(event) {
      let file = event.target.files[0];

      const storage = getStorage();
      const storageRef = ref(storage, "products-contents-image/" + file.name);
      uploadBytes(storageRef, file)
      .then((snapshot) => {
        this.product_img_full_path2 = snapshot.metadata.name;
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .inline-menu {
    margin-top: 3vw;
    margin-bottom: 3vw;
  }
  .inline-menu span {
    margin: 0 2vw;
  }
  .select-tab {
    font-weight: bold;
    background-color: #a37200;
  }
  #product-tab-title {
    padding: 1vw 2vw;
  }

  #input-product {
    width: 80%;
    margin: 0 auto;
  }
  .history-block {
    width: 40%;
  }
  .history {
    border: 1px solid black;
    height: 32vw;
    background-color: white;
    overflow-y: auto;
    padding-bottom: 2vw;
  }
  .product-dl {
    padding-left: 2vw;
    display: grid;
    text-align: left;
  }
  .input-block {
    padding-left: 2vw;
    display: grid;
    width: 50%;
  }
  .ud-div {
    position: relative;
    text-align: right;
  }
  .ud-icon {
    width: 1.2vw;
    height: 1.2vw;
    margin: 0.4vw 0.5vw;
  }
  .input-table input {
    width: 100%;
    margin-top: 0.2vw;
    margin-bottom: 0.2vw;
    height: 2vw;
    padding-left: 0.5vw;
  }
  .input-table textarea {
    width: 100%;
    margin-top: 0.2vw;
    margin-bottom: 0.2vw;
    padding-left: 0.5vw;
  }
  .input-table td:nth-child(1) {
    text-align: right;
    padding-right: 2vw;
    width: 30%;
  }
  .input-ud-div {
    position: relative;
  }
  .input-ud-icon {
    width: 1.8vw;
    height: 1.8vw;
    margin: 0.4vw 0.5vw;
  }
  .file-upload {
    background-color: white;
  }
  .imgs {
    height: 15vw;
    width: 100%;
    display: inline-flex;
    overflow-x: auto;
  }
  .imgs img {
    width: 12vw;
    height: auto;
    margin: 0.2vw;
  }
  .product-title {
    font-size: 1.5vw;
    font-weight: bold;
    margin-bottom: 1vw;
    margin-top: 2vw;
  }
</style>
