<template>
  <div class="home">
    <div class="slide-logo">
      <img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Ftop-slide-logo.png?alt=media&token=e4b5ab3e-ceae-469b-943d-82997fce5e30" alt="">
    </div>
    <div id="carouselExampleControls" class="carousel slide relative" data-bs-ride="carousel">
      <div class="carousel-inner relative w-full overflow-hidden">
        <div class="carousel-item relative float-left w-full">
          <img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Ftop-slide-4.png?alt=media&token=71c68bb1-679e-401f-9c7e-2f837f4b3949" class="carousel-img block" alt="yasai" />
        </div>
        <div class="carousel-item active relative float-left w-full">
          <img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Ftop-slide-1.png?alt=media&token=775cf390-e7b5-46f0-bcb7-5ee23e8c6700" class="carousel-img block" alt="hatake" />
        </div>
        <div class="carousel-item relative float-left w-full">
          <img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Ftop-slide-2.png?alt=media&token=0172cac2-7182-45e0-abfa-17e422747269" class="carousel-img block" alt="yatsugatake" />
        </div>
        <div class="carousel-item relative float-left w-full">
          <img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Ftop-slide-3.png?alt=media&token=1385a8f1-1ddf-4660-a394-1b6938a1e457" class="carousel-img block" alt="inaho" />
        </div>
      </div>
      <button class="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev" >
        <span class="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next" >
        <span class="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
    <div class="explanation-1">
      <div class="explanation-1-1">
        <img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fhome-farmy-1.png?alt=media&token=a233c3f4-4d58-4f5a-b48a-236b1ca0634a" alt="hatake illust">
      </div>
      <div class="explanation-1-2">
        <div class="explanation-1-2-1">
          <img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fhome-farmy-logo.png?alt=media&token=f3e0e5cd-c5d2-4e6c-b373-050739f511f8" alt="farmy logo">
          <span>とは…</span>
        </div>
        <div>
          <span class="leading-7">
            信州の広大な山々の麓で、
            <br/>土自体の力を最大限発揮した無農薬・無化学肥料
            <br/>栽培の田畑のオーナーズコミュニティ。
            <br/>安心・安全な野菜や米を求めている人たちが信州
            <br/>の農家さんの協力を得ながら田畑を育てていく。
            <br/>好きな時に農体験ができ、農家さんがサポートしてくれる。
            <br/>様々な体験や交流を通してつながることができる場所。
          </span>
        </div>
        <div class="read-more-div">
          <router-link class="read-more-btn mr-12" to="/about">Read more</router-link>
        </div>
      </div>
    </div>
    <div class="service-separete font-bold text-lg">SERVICE</div>
    <div class="explanation-2">
      <div class="explanation-2-1">
        <div v-on:click="toShareFarm">
          <img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fhome-farmy-2.png?alt=media&token=af7eb01d-d9a9-471f-95b5-a82bf36d0f87" alt="hatake image">
        </div>
        <div class="mt-6">
          <div>
            <span v-on:click="toShareFarm" class="text-lg font-semibold">
              シェア農家
            </span>
          </div>
          <span>
            都会にいながら信州の田畑で無<br/>農薬・無化学肥料の野菜と米を<br/>自給自足できるサービス
          </span>
        </div>
      </div>
      <div class="explanation-2-2">
        <div v-on:click="toEvents">
          <img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fhome-farmy-3.png?alt=media&token=6759e9f6-3c19-4784-8d0b-8a3e361d3731" alt="hatake people">
        </div>
        <div class="mt-6">
          <div>
            <span v-on:click="toEvents" class="text-lg font-semibold">
            イベント
            </span>
          </div>
        </div>
        <div>
          <span>
            春夏秋冬に合わせて、毎月新し<br/>いテーマで行われるイベントや<br/>交流会に参加し放題のサービス
          </span>
        </div>
      </div>
    </div>
    <div class="explanation-3">
      <router-link class="online-info-session-btn" to="/online-seminar">初めての方はまずはお気軽に、オンライン説明会！</router-link>
    </div>
    <div class="service-separete">NEWS</div>
    <div class="explanation-4">
      <div class="explanation-4-inner">
        <div v-for="(value, key) in news" :key="key">
          <News :value='value'></News>
        </div>
      </div>
    </div>
    <router-link class="news-list-btn" to="/news-list">Read more →</router-link>
  </div>
</template>

<script>
import firebase from "../main.js"
import { collection, getDocs } from "firebase/firestore";

import News from '../components/News.vue';

export default {
  name: 'Home',
  components:  {
    News
  },
  data() {
    return {
      news: []
    }
  },
  props: {
    msg: String
  },
  mounted() {
    this.awaitFunc();
  },
  methods: {
    async awaitFunc() {
      const querySnapshot = await getDocs(collection(firebase.db, "News"));
      let tempNews = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        tempNews.push(data);
      });
      tempNews.sort((a, b) => b.datetime - a.datetime);
      this.news = [];
      tempNews.forEach(n => {
        this.news.push(JSON.stringify(n));
      });
    },
    toShareFarm() {
      this.$router.push("/share-farm");
    },
    toEvents() {
      this.$router.push("/events");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.slide-logo {
  position: absolute;
  width: 100%;
}
.slide-logo img {
  text-align: center;
  margin: 7vw auto;
  position: relative;
  z-index: 800;
}
#carouselExampleControls {
  height: 680px;
}
.explanation-1 {
  display: inline-flex;
}
.explanation-1-2 {
  text-align: left;
  margin-top: 6vw;
}
.explanation-1-2-1 {
  position: relative;
  display: inline-flex;
}
.explanation-1-2-1 span {
  margin-top: 1.5vw;
}
.read-more-div {
  margin-top: 1vw;
}
.read-more-btn {
  background-color: #AF9453;
  color: white;
  padding: 2px 40px;
  border-radius: 15px;
}
.explanation-2 {
  margin-top: 2vw;
  display: inline-flex;
}
.explanation-2-1, .explanation-2-2 {
  margin: 0 60px;
}
.explanation-2-1 img, .explanation-2-2 img {
  border-radius: 50%;
}
.online-info-session-btn {
  background-color: #AF9453;
  color: white;
  padding: 2.5vw 6vw;
  margin: 2vw auto 0;
  font-size: 1vw;
  display: inline-grid;
}
.explanation-4 {
  width: 100%;
  height: 12vw;
  overflow-y: auto;
}
.explanation-4-inner {
  background-color: antiquewhite;
  width: 40%;
  height: 10vw;
  overflow-y: auto;
  margin: 0 auto;
}
.carousel-item {
  width: auto;
  height: 100%;
}
.news-list-btn {
  background-color: #AF9453;
  color: white;
  padding: 0.2vw 4vw;
  margin: 0 auto;
  font-size: 0.8vw;
  border-radius: 20px;
}
@media screen and (max-width:640px) {
  .slide-logo img {
    margin: 0 auto;
    max-width: 80%;
  }
	.carousel-img {
    height: 100%;
    object-fit: cover;
  }
  .explanation-1 {
    margin-top: 20vw;
    display: block;
  }
  .explanation-1-2 {
    text-align: center;
  }
  .read-more-div {
    margin-top: 8vw;
    margin-bottom: 8vw;
  }
  .read-more-btn {
    margin: 0 auto;
  }
  .explanation-2 {
    display: block;
  }
  .explanation-2 span {
    width: 100%;
  }
  .explanation-3 {
    font-size: 3vw;
    margin-bottom: 10vw;
  }
  .online-info-session-btn {
    margin: 7vw auto 0;
    padding: 6vw 5vw;
    font-size: 3vw;
  }
  .explanation-4 {
    height: 80vw;
    margin-bottom: 6vw;
  }
  .explanation-4-inner {
    width: 100%;
    background-color: white;
    height: 100%;
  }
  #carouselExampleControls {
    height: 37vw;
  }
  .carousel-item {
    width: auto;
    height: 100%;
  }
  .news-list-btn {
    margin-top: 2vw;
    font-size: 5vw;
  }
}

</style>
