<template>
  <div class="products">
    <div class="inline-table w-full">
      <img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fevent.png?alt=media&token=56926588-46a9-4cfb-99dd-ed92eb8e209f" class="block w-full" alt="event" />
    </div>
    <div class="products-title">
      <span>商品一覧</span>
    </div>
    <div class="inline-table mt-10 products-panels">
      <div class="products-dd flex flex-wrap">
        <div class="products-item" v-for="(value, key) in products" :key="key">
          <Product :value='value'></Product>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import firebase from "../main.js"
import { collection, getDocs } from "firebase/firestore";

import Product from '../components/Product.vue';

export default {
  name: 'Products',
  components:  {
    Product 
  },
  data() {
    return {
      products: []
    }
  },
  props: {
    msg: String
  },
  mounted() {
    this.awaitFunc();

  },
  methods: {
    async awaitFunc() {
      const querySnapshot = await getDocs(collection(firebase.db, "Products"));
      let temProducts = [];
      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        if (!docData.hideFlag) {
          docData.id = doc.id;
          temProducts.push(docData);
        }
      });
      temProducts.forEach((product) => {
        this.products.push(JSON.stringify(product));
      });

    },
  }
}
</script>
<style scoped>
.products-title {
  margin-top: 2vw;
}
.products-title span {
  color: #AF9453;
  font-size: 2vw;
}
.products-item {
  margin: 2vw;
  max-width: 21vw;
}
.products-panels {
  width: 85%;
}
@media screen and (max-width:640px) {
  .products-title span {
    color: #AF9453;
    font-size: 10vw;
  }
  .products-text {
    width: 80%;
    margin: 0 auto;
  }
  .products-dd {
    display: inline-table;
    width: 100%;
  }
  .products-item {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
  .products-panels {
    width: 100%;
  }
}
</style>