<template>
  <div class="signin-signup">
    <div class="signup">
      <div class="title">
        <span>登録</span>
      </div>
      <div class="form-table">
        <table>
          <tr>
            <th>メールアドレス：</th>
            <td><input type="email" v-model="signup_email"/></td>
          </tr>
          <tr>
            <th>パスワード：</th>
            <td><input type="password" v-model="signup_password"/></td>
          </tr>
          <tr>
            <th>パスワード（確認）：</th>
            <td>
              <input type="password" v-model="signup_passwordConfirm"/>
            </td>
          </tr>
          <tr>
            <th></th>
            <td class="pw-note"><span>※パスワードは6文字以上を入力してください。</span></td>
          </tr>
        </table>
        <button class="signup-btn" @click="signup">サインアップ</button>
      </div>
    </div>
    <div class="signin">
      <div class="title">
        <span>サインイン</span>
      </div>
      <div class="form-table">
        <table>
          <tr>
            <th>メールアドレス：</th>
            <td><input type="email" v-model="signin_email"/></td>
          </tr>
          <tr>
            <th>パスワード：</th>
            <td><input type="password" v-model="signin_password"/></td>
          </tr>
        </table>
        <button class="signin-btn" @click="signin">サインイン</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";

export default {
  data() {
    return {
      signup_email: '',
      signup_password: '',
      signup_passwordConfirm: '',

      signin_email: '',
      signin_password: '',
    }
  },
  mounted() {
  },
  props: {
  },
  methods: {
    signup() {
      if (!this.signup_email) {
        alert("メールアドレスを入力してください");
        return;
      }
      if (this.signup_password != this.signup_passwordConfirm) {
        alert("パスワードとパスワード（確認）が一致しません");
        return;
      }
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, this.signup_email, this.signup_password)
      .then((user) => {
        // 成功時の処理
        console.log(user);
      })
      .catch((error) => { 
        // エラー時の処理 
        if (error.code == "auth/invalid-email") {
          alert("メールアドレスの形式が違います");
        } else if (error.code == "auth/email-already-in-use") {
          alert("このメールアドレスはすでに使われています");
        } else if (error.code == "auth/weak-password") {
          alert("パスワードは6文字以上で入力してください");
        }
      });
    },
    signin() {
      if (!this.signin_email) {
        alert("メールアドレスを入力してください");
        return;
      }
      if (!this.signin_password) {
        alert("パスワードを入力してください");
        return;
      }
      const auth = getAuth();
      signInWithEmailAndPassword( auth, this.signin_email, this.signin_password)
      .then((user) => {
        // 成功時の処理
        console.log(user);
        alert("おかえりなさい！");
        this.tabHidden();
        window.location.href = "/";
      })
      .catch((error) => { 
        // エラー時の処理 
        console.log(error);
        alert("ログインに失敗しました");
      });
    },
    tabHidden() {
      const nonSignin = document.getElementsByClassName("account-tab-non-signin")[0];
      const signin = document.getElementsByClassName("account-tab-signin")[0];
      nonSignin.style.display = "none";
      signin.style.display = "none";
    }
  }
}
</script>
<style scoped>
.signin-signup {
  margin-top: 2vw;
  display: inline-flex;
}
.signin,
.signup {
  margin: 0 3vw;
  min-height: 30vw;
}
.title {
	text-align: left;
	font-size: 2vw;
	font-weight: bold;
}
.form-table {
  height: 20vw;
  border: 1px solid black;
  padding: 1vw;
}
.form-table table {
	margin: 0 auto;
}
.form-table table th {
  height: 3vw;
  text-align: right;
}
.pw-note {
  text-align: left;
  vertical-align: top;
  color: red;
}
input {
  width: 20vw;
  font-size: 1.2vw;
  padding-left: 5px;
}
.signup-btn {
  position: relative;
  bottom: -1vw;
  background-color: #AF9453;
  color: white;
  margin-top: 0.5vw;
  padding: 1.5vw 4vw;
}
.signin-btn {
  position: relative;
  bottom: -7vw;
  background-color: #AF9453;
  color: white;
  margin-top: 0.5vw;
  padding: 1.5vw 4vw;
}

</style>