<template>
  <div class="event">
    <div class="inline-table w-full">
      <img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fevent.png?alt=media&token=56926588-46a9-4cfb-99dd-ed92eb8e209f" class="block w-full" alt="event" />
    </div>
    <div class="inline-table sm:w-5/6 md:w-5/6 lg:w-1/2 mt-10">
      <div>
        <span class="default-color text-lg tracking-[1vw]">イベントとは</span>
      </div>
      <div class="event-text mt-10">
        <span>
          一人でやると1日かかってしまう農作業も、<br/>
          仲間が集まればほんの数時間で終わってしまう。<br/>
          みんなでワイワイできるので、農作業自体が楽しいイベント。<br/>
          それ以外にも、自分たちでつくった採れたての素材をつかって、もちつきをしたり、<br/>
          味噌や醤油をつくったり。お金で買えば簡単に手に入るけれど、<br/>
          自分たちで手間暇かけたらできることを楽しみながらやってしまおう！というのが<br/>
          私たちのイベントの考え。<br/>
          参加者からの発案にもこたえて、どんどんラインナップが増えていきます。
        </span>
      </div>
    </div>
    <div class="event-title">
      <span>イベント一覧</span>
    </div>
    <div class="inline-table mt-10 event-panels">
      <div class="events-dd flex flex-wrap">
        <div class="event-item" v-for="(value, key) in events" :key="key">
          <Event :value='value'></Event>
        </div>
      </div>
    </div>
    <div class="inline-table sm:w-5/6 md:w-5/6 lg:w-1/2 mt-10">
      <div class="mt-10">
        <carousel :items-to-show="1.5">
          <slide v-for="image in images" :key="image">
            <img v-bind:src="image" alt="image">
          </slide>

          <template #addons>
            <navigation />
            <pagination />
          </template>
        </carousel>
      </div>
    </div>
  </div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

import firebase from "../main.js"
import { collection, getDocs, orderBy } from "firebase/firestore";

import Event from '../components/Event.vue';

export default {
  name: 'Events',
  components:  {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Event
  },
  data() {
    return {
      images: [
        "https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fevent-bottom1.jpg?alt=media&token=434ce15d-a22a-4672-b8d2-a7ca3f02e7b9",
        "https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fevent-bottom2.jpg?alt=media&token=cf261e19-9862-4421-9022-26515a0f90a6",
        "https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fevent-bottom3.jpg?alt=media&token=794e94a9-c1ac-4137-a899-151120dc789d",
        "https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fevent-bottom4.jpg?alt=media&token=3b351404-5bfd-4023-b049-792f9581eceb",
        "https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fevent-bottom5.jpg?alt=media&token=2ea01759-5be7-49d3-8c23-cc5b222323fb",
      ],
      events: []
    }
  },
  props: {
    msg: String
  },
  mounted() {
    this.awaitFunc();

  },
  methods: {
    async awaitFunc() {
      const querySnapshot = await getDocs(collection(firebase.db, "Events"), orderBy("startDatetime", "desc"));
      let temEvents = [];
      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        if (!docData.hideFlag) {
          docData.id = doc.id;
          temEvents.push(docData);
        }
      });
      temEvents.sort((a, b) => b.startDatetime.seconds - a.startDatetime.seconds);
      temEvents.forEach((event) => {
        this.events.push(JSON.stringify(event));
      });

    },
  }
}
</script>
<style scoped>
  .event-title {
    margin-top: 2vw;
  }
  .event-title span {
    color: #AF9453;
    font-size: 2vw;
  }
  .event-item {
    margin: 2vw;
    max-width: 21vw;
  }
  .event-panels {
    width: 85%;
  }
  @media screen and (max-width:640px) {
    .event-text {
      width: 80%;
      margin: 0 auto;
    }
    .events-dd {
      display: inline-table;
    }
    .event-item {
      width: 100%;
      max-width: 100%;
    }
  }
</style>