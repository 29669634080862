<template>
  <div class="shareFarm">
    <div id="shareCarouselExampleControls" class="carousel slide relative" data-bs-ride="carousel">
      <div class="carousel-inner relative w-full overflow-hidden">
        <div class="carousel-item active relative float-left">
          <img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Ftop-slide-2.png?alt=media&token=0172cac2-7182-45e0-abfa-17e422747269" class="block w-full" alt="yatsugatake" />
        </div>
        <div class="carousel-item relative float-left">
          <img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fshare-taue.jpg?alt=media&token=0803a6bc-b163-480b-b3a0-5be8b239ca26" class="block w-full" alt="taue" />
        </div>
      </div>
      <button class="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0" type="button" data-bs-target="#shareCarouselExampleControls" data-bs-slide="prev" >
        <span class="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0" type="button" data-bs-target="#shareCarouselExampleControls" data-bs-slide="next" >
        <span class="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
    <div class="share-text inline-table mt-10">
      <div>
        <span class="default-color text-lg tracking-[1vw]">シェア農家とは</span>
      </div>
      <div class="share-discription description-text">
        <span class="">
          畑の一部のスペースを借りるのが、一般的なシェア畑。
          日々、自分で管理しなくてはいけず、少し大変だったり、つい楽をしようと化学肥料を使ってしまったり…。
          お隣さんの作物や雑草、農法の違いなどから、ときにはトラブルに繋がってしまうことも。
          <font>「シェア農家」</font> は、 欧米で広がる、<font>”地域支援型農業”</font>（CSA：Community Supported Agriculture）という、
          複数の利用者が特定の農家の会員となり、農作物を直接、定期購入する、新たな農業の仕組みを採用。
          <font>一つの農家を間接的に所有</font>することで、 日常の管理をプロの農家に任せることができるだけでなく、
          シェア農家が広がると、フードロスや高齢化と収益の不安定化に 悩む農家さんたちを助ける貢献ができます。
          FARMYでは、信州の農家さんと、同じオーナー仲間同士で、 時間と田畑を共有してお互いに支え合い、
          都会で暮らしながら、<font>無農薬の農作物を自給自足</font>できるコミュニティ。
          通って来た時には、農家さんと一緒に農作業を楽しみながら田舎を体験し、 いない間は農家さんが田畑の管理をしてくれるので安心。
          定期的なイベントを通じて、 新しい仲間や現地の人たちとの交流もできます。
          通うほどに楽しさが増えていく、 仲間と農家さんを支える新たな農業の形です。
        </span>
      </div>
      <div>
        <img class="inline-flex justify-center" src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fhome-farmy-1.png?alt=media&token=a233c3f4-4d58-4f5a-b48a-236b1ca0634a" alt="hatake illust">
      </div>
      <div>
        <table class="point-table">
          <tr>
            <td rowspan="2"><img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fshare_1.png?alt=media&token=93645c0b-7899-4b86-b36a-08ebc4628871" alt=""></td>
            <td class="point"><span>Point1</span></td>
          </tr>
          <tr>
            <td class="description description-text"><span>信州の無農薬・無化学肥料田畑のオーナーになれます。</span></td>
          </tr>
          <tr>
            <td rowspan="2"><img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fshare_2.png?alt=media&token=f3d5cafe-e1c2-468d-b3e0-b08f8a4897fc" alt=""></td>
            <td class="point"><span>Point2</span></td>
          </tr>
          <tr>
            <td class="description description-text"><span>好きな時に農業体験に来ることができます。<br>それ以外は地元の農家さんが田畑の管理をしてくれます。</span></td>
          </tr>
          <tr>
            <td rowspan="2"><img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fshare_3.png?alt=media&token=c8e7f8aa-b908-4a5b-b75e-b246edca3aeb" alt=""></td>
            <td class="point"><span>Point3</span></td>
          </tr>
          <tr>
            <td class="description description-text"><span>定期的に安心安全な美味しい野菜やお米が届きます。</span></td>
          </tr>
        </table>
      </div>
      <div class="mt-8 mb-2">
        <span class="default-color text-lg tracking-[1vw]">自然農法とは</span>
      </div>
      <div class="mt-10">
        <span class="description-text">
          山の森と同じように、農薬や化学肥料をつかわなくても発酵微生物による<br>
          分解と循環の自然サイクルを見習い、できる限り近づけて農作物をつくる<br>
          農業のあり方です。<br>
          人によってやり方はいろいろありますが、私たちは機械もつかうし、落ち<br>
          葉や草木を集めて発酵完熟堆肥づくりもして使用します。<br>
          原則として、農薬や化学肥料は使いません。
        </span>
      </div>
      <div class=" mt-20">
        <span class="default-color text-lg tracking-[1vw]">オーナーになることで<br>解決できる課題</span>
      </div>
      <div class="mt-10">
        <table class="kaiketsu">
          <tr>
            <td><img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fshare_6.png?alt=media&token=b43843ca-5074-4f68-9c19-510eefc2f133" alt=""></td>
            <td><img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fshare_7.png?alt=media&token=afd206da-256c-4c66-a5cc-cff80d4aa61a" alt=""></td>
          </tr>
          <tr>
            <td class="kaiketsu_title">オーナー様</td>
            <td class="kaiketsu_title">農家さん</td>
          </tr>
          <tr>
            <td class="kaiketsu_description description-text">
              <span>
                ＊美味しくて安心な食<br>
                ＊特別な体験 信頼できる生産者さんとの繋がり<br>
                ＊地域の人との繋がり<br>
                ＊子どもと共に農業体験ができ、食や自然の大切さを学ぶことができる
              </span>
            </td>
            <td class="kaiketsu_description description-text">
              <span>
                ＊フードロス<br>
                ＊農家の経営安定<br>
                ＊農家の後継者問題<br>
                ＊土、水の汚染<br>
                ＊耕作放棄地<br>
                ＊無農薬・有機栽培を増やすことができる
              </span>
            </td>
          </tr>
        </table>
      </div>
      <div class="mt-8 mb-2">
        <span class="default-color text-lg tracking-[1vw]">場所</span>
      </div>
      <table>
        <tr>
          <td><img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fshare_8.png?alt=media&token=7b80dfec-4f65-4f13-b13c-d425630cb165" alt=""></td>
          <td class="basho">
            <span class="description-text">
              長野県中央部、八ヶ岳山麓の西南部に位置する東西に長い地域で、標高が1000m前後<br>
              の高原地帯である、原村。<br>
              長野県のほぼ中央に位置し、北アルプスの山並みを背景に田園風景が広がる塩尻市。<br>
              そんな土地にある田畑です！<br><br>
              ■東京方面から<br>
              車や高速バスで約２時間半、電車で約２時間<br><br>
              ️■名古屋方面から<br>
              車や高速バスで約２時間半、電車で約２時間<br><br>
            </span>
          </td>
        </tr>
      </table>
      <div class="mt-8 mb-2">
        <span class="default-color text-lg tracking-[1vw]">概要</span>
        <table style="display: none;" class="gaiyou">
          <tr>
            <td>
              <span class="gaiyou-title">会費</span>
            </td>
            <td>
              <span class="gaiyou-title">無農薬・無化学肥料で<br>育てたお米と野菜</span>

            </td>
            <td>
              <span class="gaiyou-title">イベント</span>

            </td>
          </tr>
          <tr>
            <td>
              <span class="gaiyou-description">
                <p>オンライン説明会に<br>ご参加ください</p>
              </span>
            </td>
            <td>
              <span class="gaiyou-description">
                ・収穫したお米をみんなでシェア（１世帯当たり30キロ目標）
                <br><br>
                ・野菜（５～８品）を月１回<br>（６月～11月期間限定）配送
                <br><br>
                ※機構の変動や不測の事態により収穫に影響があった場合に、目標収穫達しない場合があります。<br>
                ※収穫したお米は11月以降順次配送いたします。
              </span>
            </td>
            <td>
              <span class="gaiyou-description">
                どのイベントにも<br>
                参加し放題！
                <br><br>
                ※１アカウントで家族（同一世帯）なら何人で参加してもOK
                <br>
                ※手作り食品づくりイベントは、材料費・講師料など別途費用が掛かる場合がございます。
              </span>
            </td>
          </tr>
        </table>
        <span class="gaiyou"><img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fshcare_9.png?alt=media&token=f46a23db-9792-4206-9eca-02e1b49ca3f9" alt=""></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ShareFarm',
  components:  {
  },
  data() {
  },
  props: {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style scoped>
  font {
    background-color: #F8B389;
  }
  .share-discription {
    width: 40vw;
    margin: 3vw auto 0;
  }
  #shareCarouselExampleControls {
    height: 680px;
  }
  .point-table {
    margin: 0 auto;
  }
  .point {
    text-align: left;
    color: #AF9453;
  }
  .description {
    text-align: left;
    vertical-align: top;
  }
  .description-text {
    font-size: 13px;
    line-height: 24px;
  }
  .kaiketsu {
    margin: 0 auto;
  }
  .kaiketsu td {
    width: 20vw;
  }
  .kaiketsu_title {
    color: #AF9453;
  }
  .kaiketsu_description {
    text-align: left;
    font-size: 0.8vw;
    padding: 0 2vw;
  }
  .basho {
    text-align: left;
    font-size: 0.8vw;
  }
  .gaiyou {
    margin: 0 auto;
    border-collapse: separate;
    border-spacing: 4px 0px;
  }
  .gaiyou td {
    width: 16.5vw;
    background-color: white;
  }
  .gaiyou-title {
    color: #AF9453;
    font-size: 0.9vw;
    font-weight: 600;
    padding: 3vw 0;
  }
  .gaiyou-description {
    font-size: 13px;
  }
  .gaiyou-description p {
    font-size: 15px;
    font-weight: bold;
  }
  @media screen and (max-width:640px) {
    #shareCarouselExampleControls {
      display: none;
    }
    .share-discription {
      width: unset;
      margin: 5vw 0;
    }
    .share-text {
      width: 80%;
    }
    .kaiketsu {
      width: 100%;
    }
    .kaiketsu_description {
      text-align: left;
      font-size: 3vw;
      padding: 0 2vw;
    }
  }
</style>