<template>
  <div class="event-detail">
    <div v-if="item.priceDispFlag == true" class="card">
      <div class="card-title">{{ item.title }}</div>
      <div class="price-title">価格</div>
      <div>
        <table class="price-table">
          <tr v-if="plan === 'premium'">
            <td>プレミアム会員価格：</td>
            <td v-if="item.premiumPrice != '' && item.premiumPriceNum == 0">無料</td>
            <td v-else-if="item.premiumPrice != '' && item.premiumPriceNum > 0">¥{{ item.premiumPriceLocalString }}</td>
            <td v-else>無料</td>
            <td v-if="item.premiumPrice != ''"><button @click="sendPay(item.premiumUrl)">申込</button></td>
          </tr>
          <tr v-if="plan === 'basic'">
            <td>ベーシック会員価格：</td>
            <td v-if="item.basicPrice > 0">¥{{ item.basicPriceLocalString }}</td>
            <td v-else>無料</td>
            <td v-if="item.basicPrice > 0"><button @click="sendPay(item.basicUrl)">申込</button></td>
          </tr>
          <tr v-if="plan === 'supporter'">
            <td>サポーター＆非会員価格：</td>
            <td v-if="item.otherPrice != '' && item.otherPriceNum == 0">無料</td>
            <td v-else-if="item.otherPrice != '' && item.otherPriceNum > 0">¥{{ item.otherPriceLocalString }}</td>
            <td v-if="item.otherPrice != ''"><button @click="sendPay(item.otherUrl)">申込</button></td>
          </tr>
          <tr v-if="item.studentPrice > 0">
            <td>小中高価格：</td>
            <td>¥{{ item.studentPriceLocalString }}</td>
            <td><button @click="sendPay(item.studentUrl)">申込</button></td>
          </tr>
          <tr v-if="item.elementaryStudentPrice > 0">
            <td>小学生価格：</td>
            <td>¥{{ item.elementaryStudentPriceLocalString }}</td>
            <td><button @click="sendPay(item.elementaryStudentUrl)">申込</button></td>
          </tr>
          <tr v-if="item.babyPrice > 0">
            <td>未就学児価格：</td>
            <td>¥{{ item.babyPriceLocalString }}</td>
            <td><button @click="sendPay(item.babyUrl)">申込</button></td>
          </tr>
        </table>
      </div>
      <div>
        <span class="note" style="display: none;" v-if="!login">会員の方はログインしてください</span>
      </div>
    </div>
    <div class="content-div">
      <div>
        <img class="top-img" v-bind:src="item.downloadUrl" alt="">
      </div>
      <div v-if="item.priceDispFlag == false" class="content-event-title">{{ item.title }}</div>
      <div class="content-title">内容</div>
      <div class="content">
        <span v-html="autoLink(item.content)"></span>
      </div>
      <div v-if="item.downloadUrl2">
        <img class="top-img" v-bind:src="item.downloadUrl2" alt="">
      </div>
      <div class="content">
        <span v-html="autoLink(item.content2)"></span>
      </div>
      <div v-if="item.priceDispFlag == false" class="mt-16 mb-16">
        <a class="application_url" v-bind:href="item.applicationUrl">申し込みはこちら</a>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../main.js"
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, query, where, doc, getDocs, getDoc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export default {
  components: {
  },
  data() {
    return {
      id: null,
      item: {},
      loading: false,
      lineItems: [
        {
          price: "prod_MBRAl9jqT2tGWY",
          quantity: 1
        }
      ],
      plan: "",
      login: false,
    }
  },
  props: {
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.awaitFunc();
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.login = true;
        this.getUser(user.uid);
      } else {
        this.plan = 'supporter'
      }
    });
  },
  methods: {
    async awaitFunc() {
      const docRef = doc(firebase.db, "Events", this.id);
      const querySnapshot = await getDoc(docRef);
      const docData = querySnapshot.data();
      this.item = docData;
      this.item.premiumPriceNum = Number(docData.premiumPrice);
      this.item.premiumPriceLocalString = Number(docData.premiumPrice).toLocaleString();

      this.item.basicPriceNum = Number(docData.basicPrice);
      this.item.basicPriceLocalString = Number(docData.basicPrice).toLocaleString();

      this.item.otherPriceNum = Number(docData.otherPrice);
      this.item.otherPriceLocalString = Number(docData.otherPrice).toLocaleString();

      this.item.studentPriceNum = Number(docData.studentPrice);
      this.item.studentPriceLocalString = Number(docData.studentPrice).toLocaleString();

      this.item.elementaryStudentPriceNum = docData.elementaryStudentPrice ? Number(docData.elementaryStudentPrice) : 0;
      this.item.elementaryStudentPriceLocalString = docData.elementaryStudentPrice ? Number(docData.elementaryStudentPrice).toLocaleString() : 0;

      this.item.babyPriceNum = Number(docData.babyPrice);
      this.item.babyPriceLocalString = Number(docData.babyPrice).toLocaleString();

      if (!("priceDispFlag" in this.item)) {
        this.item.priceDispFlag = true;
      }

      if (this.item.content.indexOf("gagagagazou") >= 0) {
        let contents = this.item.content.split("gagagagazou");
        this.item.content = contents[0];
        this.item.content2 = contents[1];
      }

      this.downloadUrl();
      this.downloadUrl2();
    },
    submit() {
      this.$refs.checkoutRef.redirectToCheckout();
    },
    async getUser(uid) {
        const q = query(collection(firebase.db, "Users"), where("authId", "==", uid));
        const qSnap = await getDocs(q);
        qSnap.forEach((doc) => {
          this.plan = doc.data().plan;
        });
    },
    sendPay(url) {
      window.open(url, '_blank');
    },
    async downloadUrl() {
      if (this.item.imageurl) {
        const storage = getStorage();
        const imgFullPath = this.item.imageurl;
        let targetItem = this;
        await getDownloadURL(ref(storage, "events-contents-image/" + imgFullPath))
        .then(function(url) {
          targetItem.item.downloadUrl = url;
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
    async downloadUrl2() {
      if (this.item.imageurl2) {
        const storage = getStorage();
        const imgFullPath2 = this.item.imageurl2;
        let targetItem2 = this;
        await getDownloadURL(ref(storage, "events-contents-image/" + imgFullPath2))
        .then(function(url) {
          targetItem2.item.downloadUrl2 = url;
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
    autoLink(text) {
      return text ? text.replace(/(https?:\/\/[^\s]*)/g, "<a href='$1' style='color:blue;' target='_blank'>$1</a>") : '';
    },
  }
}
</script>
<style scoped>
  button {
    background-color: #AF9453;
    color: white;
    padding: 2px 5px;
    border-radius: 2px;
    margin-left: 10px;
  }
  .top-img {
    height: 30vw;
    object-fit: cover;
    margin: 0 auto;
  }
  .card {
    position: fixed;
    width: 20vw;
    height: 30vw;
    background-color: rgba(255, 255, 255, 0.6);
    top: 6vw;
    right: 3vw;
    border: 1px solid black;
  }
  .card-title {
    color: #AF9453;
    font-size: 1.8vw;
    margin-bottom: 6vw;
    padding: 0 10px;
  }
  .price-title {
    text-align: center;
    font-weight: bold;
    font-size: 1.3vw;
  }
  .price-table {
    margin-left: 2vw;
  }
  .price-table tr {
    height: 2vw;
  }
  .price-table td:nth-child(1) {
    text-align: right;
  }
  .price-table td:nth-child(2) {
    text-align: left;
  }
  .regi {
    background-color: #AF9453;
    color: white;
    margin-top: 0.5vw;
    padding: 2vw 6vw;
  }
  .content {
    white-space: pre-line;
  }
  .content-div {
    width: 50%;
    margin: 0 auto;
  }
  .content-event-title {
    color: #af9453;
    font-size: 2vw;
    width: 100%;
    text-align: center;
    margin: 1vw 0;
  }
  .content-title {
    color: #af9453;
    font-size: 1.6vw;
    width: 100%;
    text-align: center;
    margin: 1vw 0;
  }
  .content {
    text-align: left;
  }
  .note {
    color: red;
  }
  .application_url {
    background-color: #AF9453;
    color: white;
    padding: 2vw 2vw;
    margin: 0 auto;
    font-size: 1.2vw;
  }
  @media screen and (max-width:640px) {
    .content-div {
      width: 80%;
    }
    .content-event-title {
      font-size: 6vw;
    }
    .card-title,
    .price-title,
    .content-title {
      font-size: 5vw;
    }
    .card {
      position: relative;
      width: 80%;
      height: auto;
      background-color: white;
      top: auto;
      right: auto;
      margin: 3vw auto;
      border: 1px solid black;
    }
    .price-table {
      margin: 0 auto;
      font-size: 4vw;
    }
    .top-img {
      height: 40vw;
      object-fit: cover;
      margin: 0 auto;
    }
    .application_url {
      background-color: #AF9453;
      color: white;
      padding: 3vw 4vw;
      margin: 0 auto;
      font-size: 4.8vw;
    }
  }
</style>