<template>
  <div class="onlineseminar">
    <div class="inline-table w-full">
      <img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fonlineseminar.png?alt=media&token=5640fb9a-8936-4b45-8b5d-2c0c13e67c2a" class="block w-full" alt="yasai" />
    </div>
    <div class="content inline-table mt-10">
      <div>
        <span class="default-color text-base tracking-[1vw]">まずは気軽に聞いてみよう！</span>
      </div>
      <div class="mt-10">
        <span class="default-color text-4xl">オンライン説明会</span>
      </div>
      <div class="mt-10">
        <span>
          シェア農家ってなんだろう？どういう仕組みなの？<br/>
          自然農ってどんな感じ？<br/>
          FARMYの取り組み内容を具体的にご説明いたします。<br/>
          無料のオンライン説明会ですのでお気軽にご参加ください！<br/>
          <br/>
          オンライン説明会のスケジュールは申し込みフォーマットに<br/>
          記載されていますので、ご都合の良い日程をお選びください。<br/>
          <br/>
          視聴方法は申し込み完了後、メールにてご案内いたします！
        </span>
      </div>
      <!-- <div class="google-cal">
        <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ebdebc&ctz=Asia%2FTokyo&showTitle=0&showPrint=0&showTabs=0&showCalendars=0&showTz=0&src=286075e4ccff32882f2d17aa28a895c684808e2c64dbac9792e65f97954e28ca%40group.calendar.google.com&ctz=UTC"
          id="google-cal-iframe"
          style="border: 0"
          width="100%"
          height="auto"
          frameborder="0"
          scrolling="no">
        </iframe>
      </div> -->
      <div class="mt-10">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSd2U8mOz4Lcum7QxdsyAcYb2c9GKnII1EYBxs-Whigb2BqfgA/viewform">参加する</a>
      </div>
      <div class="grid place-content-center">
        <img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fhome-farmy-1.png?alt=media&token=a233c3f4-4d58-4f5a-b48a-236b1ca0634a" alt="hatake illust">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OnlineSeminar',
  components:  {
  },
  data() {
  },
  props: {
  },
  mounted() {
    // if (window.innerWidth > 640) {
    //   document.getElementById("google-cal-iframe").height = 600;
    // } else {
    //   document.getElementById("google-cal-iframe").height = 300;
    // }
  },
  methods: {
  }
}
</script>
<style scoped>
  a {
    background-color: #AF9453;
    color: white;
    padding: 1vw 7vw;
  }
  .google-cal {
    margin-top: 1vw;
  }
  iframe {
    border: none !important;
  }
  @media screen and (max-width:640px) {
    .content {
      width: 80%;
    }
  }
</style>
