<template>
  <div class="company">
    <div class="inline-table mt-10">
      <img src="https://firebasestorage.googleapis.com/v0/b/farmy-demo.appspot.com/o/site-image%2Fcompany.png?alt=media&token=3d011297-cace-4587-9d42-f8987b303aaf" class="block w-full" alt="yasai" />
    </div>
    <div class="com inline-table w-1/2 mt-10 text-left">
      <div>
        <span class="default-color text-2xl">会社概要</span>
      </div>
      <div class="com-title mt-10">
        <span class="default-color text-4xl">Unity 合同会社</span>
      </div>
      <div class="com-text mt-10">
        <table>
          <tr>
            <td class="w-56">代表者</td>
            <td>日吉 有為</td>
          </tr>
          <tr>
            <td>所在地</td>
            <td>長野県塩尻市大門1番28号</td>
          </tr>
          <tr>
            <td>資本金</td>
            <td>440万円</td>
          </tr>
          <tr>
            <td>設立</td>
            <td>2011年12月14日</td>
          </tr>
          <tr>
            <td>旅行業者登録</td>
            <td>地域限定旅行業務（長野県知事登録旅行業 第地域ー６６７号）</td>
          </tr>
        </table>
      </div>
      <div class="mt-10">
        <span>
          Unityは「チャレンジする人たちを応援したい」「もっと起業や挑戦のハードルを下げたい」という想いで、当時まだほとんどなかったシェアスペース・シェアオフィスサービスを2011年に東京・六本木で始めました。 コミュニティの輪を広げながら、2016年からは社会人のための勉強会、セミナー、著名講師を招いての講演会などを行い、2019年までの4年間で延べ4000名以上の方々にご参加いただきました。<br/>
          2020年のコロナ禍により、運営メンバー全員が都心から地方に移住したことをきっかけに、地域活性・地方創生・農業といったジャンルにも活動の幅を広げることとなりました。 オンラインの時代になりましたが、やはり、人と人とがリアルに接し、情報や体験や経験を共にすることで、学び、成長し、自立し、自分の人生を自分で選択できるようになっていく。そんな場の提供をこれからも続けていきます。<br/>
          わたしたちは、教育、イベント、シェアリングエコノミー事業を通じて、自由な大人に溢れた社会をつくる会社です。
        </span>
      </div>
      <div class="mt-10">
        <router-link class="router-btn" to="/specified-commercial">特定商取引に基づく表記</router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Company',
  props: {
  },
  mounted() {
  }
}
</script>
<style scoped>
  .router-btn {
    background-color: #AF9453;
    color: white;
    padding: 1vw 2vw;
  }
  @media screen and (max-width:640px) {
    .com {
      width: 90%;
    }
    table td:nth-child(1) {
      width: 40%;
    }
  }
</style>