<template>
  <div class="product">
    <div class="product_content">
      <img v-bind:src="item.downloadUrl" alt="">
      <div class="title">
        <span>{{ item.title }}</span>
      </div>
      <div class="btn-div">
        <router-link class="detail-btn" :to="{name: 'ProductDetail', query: {id: item.id}}">詳細</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export default {
  data() {
    return {
      item: {},
      showContent: false
    }
  },
  props: {
    value: String
  },
  mounted() {
    this.item = JSON.parse(this.value);
    this.downloadUrl();
  },
  methods: {
    async downloadUrl() {
      if (this.item.imageurl) {
        const storage = getStorage();
        const imgFullPath = this.item.imageurl;
        let targetItem = this;
        await getDownloadURL(ref(storage, "products-contents-image/" + imgFullPath))
        .then(function(url) {
          targetItem.item.downloadUrl = url;
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
    openModal() {
      this.showContent = true;
    },
    closeModal() {
      this.showContent = false;
    }
  }
}
</script>
<style scoped>
.product {
  display: inline-flex;
  width: 17vw;
  background-color: white;
  border-radius: 5px;
}
img {
  width: 16vw;
  height: 9vw;
  object-fit: cover;
  margin: 0.5vw 0.5vw 0;
  object-position: 0 0;
}
table {
  position: relative;
}
table td {
  text-align: left;
  padding-left: 10px;
  vertical-align: top;
  font-size: 1.3vw;
}
.btn-div {
  margin-bottom: 1vw;
}
.detail-btn {
  background-color: #af9453;
  color: white;
  padding: 0.5vw 2vw;
}
.title span {
  height: 5vw;
  max-height: 5vw;
  color: #af9453;
  font-size: 1.2vw;
  position: relative;
  line-height: 1.6vw;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-wrap: anywhere;
  overflow: hidden;
  text-align: left;
  padding-left: 6px;
}
@media screen and (max-width:640px) {
  .product {
    display: inline-flex;
    width: 80%;
    border: 1px solid black;
    margin-bottom: 6vw;
  }
  .product_content {
    width: 100%;
  }
  img {
    width: 100%;
    height: 50vw;
    object-fit: cover;
    margin: 0 auto;
  }
  .fin_product {
    position: absolute;
    color: white;
    font-weight: bold;
    font-size: 4vw;
    width: 80%;
    top: 20vw;
    right: 6vw;
    background-color: rgba(0,0,0,0.6);
  }
  .title span {
    color: #af9453;
    font-size: 5vw;
    position: relative;
    line-height: 5.2vw;
    text-align: center;
    padding: 0;
    margin: 2vw 0 4vw;
    max-height: 17vw;
    height: auto;
    word-break: keep-all;
  }
  table td {
    text-align: left;
    padding-left: 10px;
    vertical-align: top;
    font-size: 3vw;
  }
  .detail-btn {
    padding: 1vw 4vw;
  }
}
</style>