<template>
  <div class="news-list">
    <div class="news-unit" v-for="(value, key) in news" :key="key">
      <div>
        <img class="top-img" v-bind:src="value.dispUrl" alt="">
      </div>
      <div>
        <div>{{ value.title }}</div>
        <div>{{ value.subTitle }}</div>
        <div><router-link class="new-read-more" :to="{name: 'NewsDetail', query: {id: value.id}}">Read more</router-link></div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../main.js"
import { collection, getDocs } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export default {
  name: 'NewsList',
  props: {
  },
  data() {
    return {
      news: []
    }
  },
  mounted() {
    this.awaitFunc();
  },
  methods: {
    async awaitFunc() {
      const querySnapshot = await getDocs(collection(firebase.db, "News"));
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.dispUrl = null;
        this.news.push(data);
      });

      this.news.forEach(ev => {
        ev.dispUrl = this.downloadUrl(ev);
      });

    },
    async downloadUrl(data) {
      const storage = getStorage();
      await getDownloadURL(ref(storage, "news-contents-image/" + data.imageurls[0]))
      .then(function(url) {
        return url;
      })
      .catch((error) => {
        console.log(error);
      });
    },
  }
}
</script>

<style scoped>
.news-list {
  width: 100%;
}
.news-unit {
  margin: 0 auto;
  width: 754px;
  height: 265px;
}
@media screen and (max-width:640px) {
}
</style>
