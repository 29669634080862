<template>
  <div class="inline-grid w-2/3">
    <span class="text-lg">以下のフォームにご入力ください。</span>
    <span class="text-sm mb-2">３日～５日以内に担当者からご回答させていただきます。</span>
    <v-form ref="form" lazy-validation>
      <table class="w-full">
        <tr>
          <td class="text-left">お名前*</td>
          <td class="text-left">お電話番号*</td>
        </tr>
        <tr>
          <td><input v-model="contactForm.name" class="bg-transparent" placeholder="お名前" label="名前" required></td>
          <td><input v-model="contactForm.phone" class="bg-transparent" placeholder="お電話番号" label="お電話番号" required></td>
        </tr>
        <tr>
          <td colspan="2" class="text-left">メールアドレス*</td>
        </tr>
        <tr>
          <td colspan="2"><input class="w-4/5 bg-transparent" v-model="contactForm.email" placeholder="メールアドレス" label="メールアドレス" required></td>
        </tr>
        <tr>
          <td colspan="2" class="text-left">お問い合わせ内容*</td>
        </tr>
        <tr>
          <td colspan="2"><textarea rows="10" class="w-4/5 bg-transparent" v-model="contactForm.contents" placeholder="お問い合わせ内容" label="内容" required></textarea></td>
        </tr>
        <tr>
          <td colspan="2"><button class="" v-on:click="sendMail">送信</button></td>
        </tr>
      </table>
    </v-form>
  </div>
</template>

<script>
  import firebase from '../main.js';

  export default {
    data: () => ({
      contactForm: {
        name: '',
        phone: '',
        contents: '',
        email: '',
        loading: false
      },
      snackBar: {
        show: false,
        color: '',
        message: ''
      }
    }),
    methods: {
      sendMail: function () {
          this.contactForm.loading = true
          const mailer = firebase.func.httpsCallable('sendMail')

          mailer(this.contactForm)
            .then(() => {
              this.formReset()
              this.showSnackBar(
                'success',
                'お問い合わせありがとうございます。送信完了しました'
              )
            })
            .catch(err => {
              this.showSnackBar(
                'error',
                '送信に失敗しました。時間をおいて再度お試しください'
              )
              console.log(err)
            })
            .finally(() => {
              this.contactForm.loading = false
            })
      },
      showSnackBar: function (color, message) {
        this.snackBar.message = message
        this.snackBar.color = color
        this.snackBar.show = true
      },
      formReset: function () {
        this.$refs.form.reset()
      }
    }
  }
</script>
<style scoped>
  input, textarea {
    border-bottom: 1px solid #AF9453;
  }
  button {
    background-color: #AF9453;
    color: white;
    padding: 1vw 2vw;
  }
  button:hover {
    background-color: #726036;
  }
	@media screen and (max-width:640px) {
		.contact {
			width: 100%;
		}
	}
</style>