import { initializeApp } from "firebase/app";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB-0ApaXRNObpFVw3bevzMePhbA_iIKvF0",
  authDomain: "farmy-demo.firebaseapp.com",
  projectId: "farmy-demo",
  storageBucket: "farmy-demo.appspot.com",
  messagingSenderId: "511782601905",
  appId: "1:511782601905:web:8b8928255dc12435cd8e90",
  measurementId: "G-0F0GYDZ1CE"
};

const app = initializeApp(firebaseConfig);

export default app;