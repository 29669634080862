<template>
  <div class="ryoko">
    <h1>旅行業法に基づく表示</h1>
    <table>
      <tr>
        <td>名称</td>
        <td>Unity合同会社</td>
      </tr>
      <tr>
        <td>業務範囲</td>
        <td>国内旅行（地域限定）</td>
      </tr>
      <tr>
        <td>登録番号</td>
        <td>長野県知事登録旅行業 地域ー667号</td>
      </tr>
      <tr>
        <td>登録年月日</td>
        <td>2022年4月13日</td>
      </tr>
      <tr>
        <td>有効期限</td>
        <td>2027年4月12日</td>
      </tr>
      <tr>
        <td>営業所</td>
        <td>本社営業所、原営業所</td>
      </tr>
	
      <tr>
        <td>国内旅行業務取扱管理者</td>
        <td>平田光恵</td>
      </tr>
    </table>
    <div class="zenkoku">
      <p>（一社）全国旅行業協会正会員</p>
      <p>募集型企画旅行実施可能区域</p>
      <p>（塩尻市、松本市、伊那市、辰野町、南箕輪村、山形村、朝日村、木祖村、木曽町、岡谷市、原村、茅野市、富士見町）</p>
    </div>

    <h1>旅行業約款</h1>
    <div class="zenkoku">
      <p>標準旅行業約款を適用しております。下記をご参照ください。</p>
      <p><a href="https://farmy.life/yakkan" target="_blank">旅行業約款 <ion-icon name="link-outline"></ion-icon></a></p>
      <p><a href="https://farmy.life/joken" target="_blank">募集型企画旅行条件書 <ion-icon name="link-outline"></ion-icon></a></p>
      <p><a href="https://docs.google.com/document/d/1vkPHKbHEcnZD2AMmYWK019ZkiZ0BoKnp8gre72YcSiY/edit" target="_blank">イベント参加規約 <ion-icon name="link-outline"></ion-icon></a></p>
    </div>

    <h1>取消料</h1>
    <div>
      <p>ご旅行お申し込み後、お客様のご都合で取消し（解除）をされる場合には、</p>
      <p>旅行代金に対してお1人様につき最大で下記の料率の取消料を申し受けいたします。</p>
      <p>旅行開始日の前日からさかのぼって</p>
      <div class="cansel">
        <p>20 日目（日帰り旅行にあっては10 日目）〜8 日前までの解除 旅行代金の20％</p>
        <p>7 日目〜2 日前までの解除 旅行代金の30％</p>
        <p>旅行開始日の前日の解除 旅行代金の40％</p>
        <p>当日の解除 旅行代金の50％</p>
        <p>旅行開始後の解除、または無連絡 旅行代金の100％</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
    }
  },
  props: {
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style scoped>
h1 {
  font-weight: bold;
  font-size: 1.6rem;
  margin-bottom: 1rem;
}
table {
  margin: 0 auto 1rem;
  text-align: left;
}
td {
  border: 1px solid black;
  padding: 5px 0.8rem;
}
.zenkoku {
  margin-bottom: 1vw;
}
.cansel {
  margin-top: 1vw;
}
</style>