<template>
  <div class="news-regist">
    <div class="news-title">NEWS の登録</div>
    <div id="input-news">
      <div class="inline-flex w-full">
        <div class="history-block block">
          <div class="history">
            <div class="news-dl" v-for="(item, key) in news" :key="key">
              <table>
                <tr>
                  <td>{{ item.fmtDatetime.dateFmt }}</td>
                  <td rowspan="2">{{ item.title }}</td>
                  <td rowspan="2"><ion-icon class="ud-icon" @click="deploymentNews(item)" name="chevron-forward-outline"></ion-icon></td>
                </tr>
                <tr>
                  <td>{{ item.fmtDatetime.timeFmt }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="input-block">
          <table class="input-table">
            <tr>
              <td>日時</td>
              <td><input type="datetime-local" name="news_datetime"></td>
            </tr>
            <tr>
              <td>タイトル</td>
              <td><input type="text" name="news_title"></td>
            </tr>
            <tr>
              <td>サブタイトル</td>
              <td><input type="text" name="news_sub_title"></td>
            </tr>
            <tr>
              <td>文章</td>
              <td><textarea name="news_content" id="" cols="30" rows="10"></textarea></td>
            </tr>
            <tr>
              <td>イベントURL（非動作）</td>
              <td><input type="text" name="news_url"></td>
            </tr>
            <tr>
              <td>画像</td>
              <td>
                <input type="file" @change="newsFileUpload">
              </td>
            </tr>
            <tr>
              <td></td>
              <td class="imgs">
                <div v-for="(value, key) in news_img_download_url_list" :key="key">
                  <img v-bind:src="value" alt="">
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <div class="input-ud-div">
                  <ion-icon class="input-ud-icon" @click="deleteData" name="trash-outline"></ion-icon>
                  <ion-icon class="input-ud-icon" @click="newsPostData" name="save-outline"></ion-icon>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../main.js";
import { collection, doc, addDoc, setDoc, getDocs, deleteDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default {
  name: 'NewsRegist',
  components: {
  },
  props: {
  },
  data() {
    return {
      // News
      news: [],
      news_data_id: String,
      news_img_full_path_list: [],
      news_img_download_url_list: [],
    }
  },
  mounted() {
    this.awaitFunc();
  },
  methods: {
    switchTab(key) {
      if (key == "news") {
        document.getElementById("input-news").style.display = "block";
        document.getElementById("news-tab-title").classList.add("select-tab")
      } else {
        document.getElementById("input-news").style.display = "none";
        document.getElementById("news-tab-title").classList.remove("select-tab")
      }

    },
    toDateTime(datetime) {
      var date = new Date(1970, 0, 1);
      date.setSeconds(datetime.seconds);
      date.setHours(date.getHours() + 9);
      return {
        dateFmt: date.getFullYear() + "/" + ("00" + (date.getMonth()+1)).slice(-2) + "/" + ("00" + date.getDate()).slice(-2),
        timeFmt: ("00" + date.getHours()).slice(-2) + ":" + ("00" + date.getMinutes()).slice(-2),
        datetimeVal: date.getFullYear()
                      + "-" + ("00" + (date.getMonth()+1)).slice(-2)
                      + "-" + ("00" + date.getDate()).slice(-2)
                      + "T" + ("00" + date.getHours()).slice(-2)
                      + ":" + ("00" + date.getMinutes()).slice(-2)
      };
    },
    async awaitFunc() {
      this.clearNews();
      this.news.length = 0;
      this.news_data_id = null;
      const newsQuerySnapshot = await getDocs(collection(firebase.db, "News"));
      newsQuerySnapshot.forEach((doc) => {
        var data = doc.data();
        data.fmtDatetime = this.toDateTime(data.datetime);
        data.id = doc.id;
        this.news.push(data);
      });
      this.news.sort((a, b) => b.datetime - a.datetime);

    },
    deploymentNews(item) {
      document.getElementsByName("news_title")[0].value = item.title;
      document.getElementsByName("news_sub_title")[0].value = item.subTitle;
      document.getElementsByName("news_content")[0].value = item.content;
      document.getElementsByName("news_datetime")[0].value = item.fmtDatetime.datetimeVal;
      this.news_data_id = item.id;

      this.news_img_download_url_list = [];
      this.news_img_full_path_list = item.imageurls ? item.imageurls : [];
      if (this.news_img_full_path_list.length > 0) {
        const storage = getStorage();
        var admin = this;
        for (let i = 0; i < this.news_img_full_path_list.length; i++) {
          const imgFullPath = this.news_img_full_path_list[i];
          getDownloadURL(ref(storage, "news-contents-image/" + imgFullPath))
          .then(function(url) {
            admin.news_img_download_url_list.push(url);
          })
          .catch((error) => {
            console.log(error);
          });
        }
      }
    },
    clearNews() {
      document.getElementsByName("news_title")[0].value = "";
      document.getElementsByName("news_sub_title")[0].value = "";
      document.getElementsByName("news_content")[0].value = "";
      document.getElementsByName("news_datetime")[0].value = "";
    },
    async newsPostData() {
      const data = {
        title: document.getElementsByName("news_title")[0].value,
        subTitle: document.getElementsByName("news_sub_title")[0].value,
        content: document.getElementsByName("news_content")[0].value,
        datetime: new Date(document.getElementsByName("news_datetime")[0].value),
        updateDate: new Date(),
        imageurls: this.news_img_full_path_list
      };
      if (this.news_data_id) {
        await setDoc(doc(firebase.db, "News", this.news_data_id), data);
        alert("ニュースデータが更新されました");

      } else {
        data.createDate = new Date();
        const db = collection(firebase.db, "News");
        await addDoc(db, data);
        alert("ニュースデータが登録されました");
      }
      this.awaitFunc();

    },
    async deleteData() {
      if (this.news_data_id) {
        if (confirm(document.getElementsByName("news_title")[0].value + "を削除してもいいですか？")) {
          await deleteDoc(doc(firebase.db, "News", this.news_data_id));
          this.news_data_id = null;
          document.getElementsByName("news_title")[0].value = "";
          document.getElementsByName("news_sub_title")[0].value = "";
          document.getElementsByName("news_content")[0].value = "";
          document.getElementsByName("news_datetime")[0].value = "";

        }
        this.awaitFunc();

      } else {
        alert("対象データがセットされていません");
      }
    },
    newsFileUpload(event) {
      let file = event.target.files[0];

      const storage = getStorage();
      const storageRef = ref(storage, "news-contents-image/" + file.name);
      uploadBytes(storageRef, file)
      .then((snapshot) => {
        this.news_img_full_path_list.push(snapshot.metadata.name);
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .inline-menu {
    margin-top: 3vw;
    margin-bottom: 3vw;
  }
  .inline-menu span {
    margin: 0 2vw;
  }
  .select-tab {
    font-weight: bold;
    background-color: #a37200;
  }
  #news-tab-title {
    padding: 1vw 2vw;
  }

  #input-news {
    width: 80%;
    margin: 0 auto;
  }
  .history-block {
    width: 40%;
  }
  .history {
    border: 1px solid black;
    height: 32vw;
    background-color: white;
    overflow-y: auto;
    padding-bottom: 2vw;
  }
  .news-dl {
    padding-left: 2vw;
    display: grid;
    text-align: left;
  }
  .input-block {
    padding-left: 2vw;
    display: grid;
    width: 50%;
  }
  .ud-div {
    position: relative;
    text-align: right;
  }
  .ud-icon {
    width: 1.2vw;
    height: 1.2vw;
    margin: 0.4vw 0.5vw;
  }
  .input-table input {
    width: 100%;
    margin-top: 0.2vw;
    margin-bottom: 0.2vw;
    height: 2vw;
    padding-left: 0.5vw;
  }
  .input-table textarea {
    width: 100%;
    margin-top: 0.2vw;
    margin-bottom: 0.2vw;
    padding-left: 0.5vw;
  }
  .input-table td:nth-child(1) {
    text-align: right;
    padding-right: 2vw;
    width: 30%;
  }
  .input-ud-div {
    position: relative;
  }
  .input-ud-icon {
    width: 1.8vw;
    height: 1.8vw;
    margin: 0.4vw 0.5vw;
  }
  .file-upload {
    background-color: white;
  }
  .imgs {
    height: 15vw;
    width: 100%;
    display: inline-flex;
    overflow-x: auto;
  }
  .imgs img {
    width: 12vw;
    height: auto;
    margin: 0.2vw;
  }
  .news-title {
    font-size: 1.5vw;
    font-weight: bold;
    margin-bottom: 1vw;
    margin-top: 2vw;
  }
</style>
