<template>
  <div class="contact relative w-full">
    <div class="okigaru-parent relative w-1/2">
      <div class="okigaru w-2/3 inline-flex justify-center">
        <span class="flex items-center">お気軽に<br>お問い合せ<br>ください</span>
      </div>
    </div>
    <div class="contact-parent relative w-1/2">
      <ContactForm/>
    </div>
  </div>
</template>

<script>
import ContactForm from '../components/ContactForm'

export default {
  name: 'Contact',
  components: {
    ContactForm
  },
  mounted() {
  },
  data () {
    return {
      //
    }
  }
}
</script>
<style scoped>
	.okigaru {
		background-color: #AF9453;
		height: 30vw;
	}
	.contact {
		display: inline-flex;
	}
	@media screen and (max-width:640px) {
		.contact {
			display: block;
		}
		.okigaru-parent {
			width: 100%;
		}
		.okigaru {
			background-color: #AF9453;
			height: 40vw;
			color: white;
			font-size: 5vw;
			width: 100%;
			line-height: 10vw;
			letter-spacing: 4vw;
			margin-bottom: 8vw;
		}
		.contact-parent {
			width: 100%;
		}
	}
</style>