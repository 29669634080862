<template>
	<div class="user">
    <span class="plan-title">{{ planTitle }}</span>
    <table>
      <tr>
        <td><span class="title">名前：</span></td>
        <td><input type="text" name="name" v-bind:value="name"></td>
      </tr>
      <tr>
        <td>名前（カナ）：</td>
        <td><input type="text" name="nameKana" v-bind:value="nameKana"></td>
      </tr>
      <tr>
        <td>電話番号：</td>
        <td><input type="text" name="phone" v-bind:value="phone"></td>
      </tr>
      <tr>
        <td>郵便番号：</td>
        <td><input type="text" name="zipCode" v-bind:value="zipCode"></td>
      </tr>
      <tr>
        <td>都道府県：</td>
        <td><input type="text" name="prefecture" v-bind:value="prefecture"></td>
      </tr>
      <tr>
        <td>市区町村：</td>
        <td><input type="text" name="city" v-bind:value="city"></td>
      </tr>
      <tr>
        <td>住所１：</td>
        <td><input type="text" name="address1" v-bind:value="address1"></td>
      </tr>
      <tr>
        <td>住所２：</td>
        <td><input type="text" name="address2" v-bind:value="address2"></td>
      </tr>
    </table>
    <div>
      <button @click="postData">{{ btnTitle }}</button>
    </div>
	</div>
</template>
<script>
import { getAuth, onAuthStateChanged } from "firebase/auth";

import firebase from "../main.js"
import { collection, query, where, doc, addDoc, setDoc, getDocs } from "firebase/firestore";

export default {

  data() {
    return {
      hasDoc: false,
      btnTitle: "",
      uid: "",
      authId: "",
      name: "",
      nameKana: "",
      phone: "",
      zipCode: "",
      prefecture: "",
      city: "",
      address1: "",
      address2: "",

      createDate: null,

      plan: "",
      planTitle: "",
    }
  },
  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.authId = user.uid;
        this.awaitFunc();

      } else {
        confirm("サインインを行ってください");
        window.location.href = "/signin-signup";

      }
    });
  },
  props: {
  },
  methods: {
    async awaitFunc() {
      const q = query(collection(firebase.db, "Users"), where("authId", "==", this.authId));
      const qSnap = await getDocs(q);
      qSnap.forEach((doc) => {

        this.hasDoc = true;

        const docData = doc.data();
        this.uid = doc.id;
        this.name = docData.name;
        this.nameKana = docData.nameKana;
        this.phone = docData.phone;
        this.zipCode = docData.zipCode;
        this.prefecture = docData.prefecture;
        this.city = docData.city;
        this.address1 = docData.address1;
        this.address2 = docData.address2;

        this.createDate = docData.createDate;

        this.btnTitle = "更新";

        this.plan = docData.plan;

      });
      if (!this.hasDoc) {
        this.btnTitle = "登録";
      }
      if (this.plan == "premium") {
        this.planTitle = "あなたはプレミアム会員です。"

      } else if (this.plan == "basic") {
        this.planTitle = "あなたはベーシック会員です。"

      } else if (this.plan == "supporter") {
        this.planTitle = "あなたはサポーター会員です。"

      }
    },
    async postData() {
      let data = {
        name: document.getElementsByName("name")[0].value,
        nameKana: document.getElementsByName("nameKana")[0].value,
        phone: document.getElementsByName("phone")[0].value,
        zipCode: document.getElementsByName("zipCode")[0].value,
        prefecture: document.getElementsByName("prefecture")[0].value,
        city: document.getElementsByName("city")[0].value,
        address1: document.getElementsByName("address1")[0].value,
        address2: document.getElementsByName("address2")[0].value,
        authId: this.authId,
        updateDate: new Date(),
        plan: this.plan,
      };
      if (this.uid) {
        data.createDate = this.createDate;
        await setDoc(doc(firebase.db, "Users", this.uid), data);
        alert("更新しました");

      } else {
        data.createDate = new Date();
        const db = collection(firebase.db, "Users");
        await addDoc(db, data);
        alert("登録しました");
      }
    },
  }
}
</script>
<style scoped>
  button {
    background-color: #AF9453;
    color: white;
    width: 6vw;
    height: 3vw;
    text-align: center;
  }
  table {
    margin: 0 auto;
    width: 30%;
  }
  table td {
    padding-top: 1vw;
    padding-bottom: 1vw;
  }
  table td:nth-child(1) {
    text-align: right;
    width: 40%;
  }
  input {
    width: 15vw;
  }
  .user {
    display: grid;
  }
  .user div {
    margin-top: 1vw;
  }
  .title {
    width: 5vw;
  }
  .plan-title { 
    color: #AF9453;
    font-size: 1.3vw;
  }
</style>