<template>
  <div class="news-detail">
    <div class="title">{{ item.title }}</div>
    <div v-for="(value, key) in item.imageurls" :key="key">
      <img class="top-img" v-bind:src="value" alt="">
    </div>
    <div class="sub-title">{{ item.subTitle }}</div>
    <div class="content">{{ item.content }}</div>
  </div>
</template>

<script>
import firebase from "../main.js"
import { doc, getDoc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
export default {
  name: 'NewsDetail',
  data() {
    return {
      id: null,
      item : {},
    }
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.awaitFunc();
  },
  methods: {
    async awaitFunc() {
      const docRef = doc(firebase.db, "News", this.id);
      const querySnapshot = await getDoc(docRef);
      this.item = querySnapshot.data();
      for (let i = 0; i < this.item.imageurls.length; i++) {
        const storage = getStorage();
        let targetItem = this;
        await getDownloadURL(ref(storage, "news-contents-image/" + this.item.imageurls[i]))
        .then(function(url) {
          targetItem.item.imageurls[i] = url;
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
  }
}
</script>
<style scoped>
.title {
  font-size: 46px;
  color: #AF9453;
}
.sub-title {
  width: 670px;
  margin: 0 auto;
  font-size: 30px;
  color: #AF9453;
  text-align: left;
}
.news-detail {
  width: 100%;
  display: inline-grid;
}
.top-img {
  margin: 1vw auto 1vw;
  width: 670px;
  height: auto;
}
.content {
  margin: 1vw auto 1vw;
  width: 670px;
  text-align: left;
  white-space: pre-line;
}
@media screen and (max-width:640px) {
}
</style>
